import React, { useState } from 'react';
import styles from './styles.module';

import Scrollbar from 'components/Scrollbar';
import OptionToggle from '../OptionToggle';

interface OptionsProps {
    title: string;
    values: { [key: string]: any };
    onChange?: (values: string[]) => void;
}

export default function ({ title, values, onChange }: OptionsProps) {
    const [selected, setSelected] = useState([]);

    function select(key) {
        setSelected((arr) => {
            const set = new Set<string>(arr);
            if (set.has(key)) set.delete(key);
            else set.add(key);
            arr = Array.from(set.values());

            const keys = Object.keys(values);
            arr.sort((val1, val2) => (keys.indexOf(val1) < keys.indexOf(val2) ? -1 : 1));

            // prevent react warning. ugly but working ;)
            setTimeout(() => onChange && onChange(arr), 0);

            return arr;
        });
    }

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <span>{title}&nbsp;</span>
            </div>
            <Scrollbar>
                <div className={styles.values}>
                    {Object.keys(values).map((key) => (
                        <OptionToggle key={key} selected={selected.indexOf(key) >= 0} onClick={select.bind(null, key)} label={values[key]} />
                    ))}
                </div>
            </Scrollbar>
        </div>
    );
}
