import MathUtils from 'util/MathUtils';

export default class Mask {
    _masksData;
    _types;
    _flats;
    _flatsPerColor = {};
    _flatsPerFloor = {};
    _floor;
    _view;
    _zoom = 1;
    _canvas;
    _ctx;
    _needsUpdate = true;
    _flatsVisible = [];
    _hover = null;

    constructor(masksData, types, flats) {
        this._masksData = masksData;
        this._types = types;
        this._flats = flats;
        this._canvas = document.createElement('canvas');
        this._ctx = this._canvas.getContext('2d');

        flats.forEach((flat) => {
            this._flatsPerColor[flat.r + '_' + flat.g + '_' + flat.b] = flat;
        });

        this._canvas.style.pointerEvents = 'none';
    }

    resize(width, height) {
        this._canvas.width = width;
        this._canvas.height = height;
        this._needsUpdate = true;
    }

    update() {
        if (!this._needsUpdate) return;
        this._needsUpdate = false;

        const ctx = this._ctx;
        ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);

        const floorMasks = this._masksData[this._floor];
        if (!floorMasks || floorMasks.length <= 0) return;

        // traverse each polygons of a floor
        floorMasks[this._view].forEach((polygon) => {
            // get corresponding flat
            const flat = this._flatsPerColor[polygon.r + '_' + polygon.g + '_' + polygon.b];

            // is the flat sold?
            const isSold = window.soldData.indexOf(flat.name.toUpperCase()) >= 0;

            // is the flat marked as visible ?
            // AND mouse is not above flat
            // always render sold flats
            if (!isSold && this._flatsVisible.indexOf(flat.name) < 0 && this._hover !== flat.name) return;

            const type = this._types[flat.type];
            const points = polygon.points;

            // color
            let r = type.r;
            let g = type.g;
            let b = type.b;

            // hover is fully visible
            let alpha = this._hover === flat.name ? 1 : 0.5;

            if (isSold) {
                r = 100;
                g = 100;
                b = 100;
                // alpha = 0.5;
            }

            // draw polygon
            ctx.fillStyle = `rgba(${r},${g},${b}, ${alpha})`;
            ctx.beginPath();
            ctx.moveTo(...this.correctPosition(points[0]));
            for (let i = 1; i < points.length; i++) {
                ctx.lineTo(...this.correctPosition(points[i]));
            }
            ctx.lineTo(...this.correctPosition(points[0]));
            ctx.fill();
        });
    }

    drawMask(ctx, flat, viewIndex) {
        const w = ctx.canvas.width;
        const h = ctx.canvas.height;

        const type = this._types[flat.type];
        const viewMasks = this._masksData[flat.floor + 1][viewIndex];

        const polygon = viewMasks.find((viewMask) => viewMask.r === flat.r && viewMask.g === flat.g && viewMask.b === flat.b);
        const points = polygon.points;

        // hover is fully visible
        const alpha = this._hover === flat.name ? 1 : 0.5;

        // draw polygon
        ctx.fillStyle = `rgba(${type.r},${type.g},${type.b}, ${alpha})`;
        ctx.beginPath();
        ctx.moveTo(...this.correctPosition(points[0], w, h, 0));
        for (let i = 1; i < points.length; i++) {
            ctx.lineTo(...this.correctPosition(points[i], w, h, 0));
        }
        ctx.lineTo(...this.correctPosition(points[0], w, h, 0));
        ctx.fill();
    }

    correctPosition(pos, w = null, h = null, zoom = null) {
        if (!w) w = this._canvas.width;
        if (!h) h = this._canvas.height;
        if (zoom === null) zoom = this._zoom;

        const x = pos[0];
        const y = pos[1];

        const size = 2500;
        zoom = 1 + 2 * zoom;
        const scale = (zoom * Math.min(w, h)) / size;

        const startX = 0.5 * (w - size * scale);
        const startY = 0.5 * (h - size * scale);

        const newX = startX + x * scale;
        const newY = startY + y * scale;

        return [newX, newY];
    }

    inverseCorrectPosition(pos) {
        const w = this._canvas.width;
        const h = this._canvas.height;
        const x = pos[0];
        const y = pos[1];

        const size = 2500;
        const zoom = 1 + 2 * this._zoom;
        const scale = (zoom * Math.min(w, h)) / size;

        const startX = 0.5 * (w - size * scale);
        const startY = 0.5 * (h - size * scale);

        let newX = MathUtils.clamp(Math.floor((x - startX) / scale), 0, size);
        let newY = MathUtils.clamp(Math.floor((y - startY) / scale), 0, size);

        return [newX, newY];
    }

    setFlatsVisible(flats) {
        this._flatsVisible = flats.map((flat) => flat.name);
        this._needsUpdate = true;
    }

    mouseAt(x, y) {
        [x, y] = this.inverseCorrectPosition([x, y]);

        const floorMasks = this._masksData[this._floor];
        if (floorMasks && floorMasks.length > 0) {
            for (let polygon of floorMasks[this._view]) {
                if (x >= polygon.bounds.x && y >= polygon.bounds.y && x <= polygon.bounds.x + polygon.bounds.width && y <= polygon.bounds.y + polygon.bounds.height) {
                    if (MathUtils.pointInPolygon(x, y, polygon.points)) {
                        const flat = this._flatsPerColor[polygon.r + '_' + polygon.g + '_' + polygon.b];

                        // already on same flat
                        if (this._hover === flat.name) return;

                        this.setFlatHover(flat);
                        return;
                    }
                }
            }
        }

        if (this._hover) {
            this.setFlatHover(null);
        }
    }

    setFlatHover(flat) {
        this._hover = flat ? flat.name : null;
        this._needsUpdate = true;
        window.viewerUtils.updateMouseOver(flat);
    }

    getCenter(refFlat) {
        const floorMasks = this._masksData[refFlat.floor + 1];
        if (floorMasks && floorMasks.length > 0) {
            for (let polygon of floorMasks[this._view]) {
                const flat = this._flatsPerColor[polygon.r + '_' + polygon.g + '_' + polygon.b];
                if (flat.name !== refFlat.name) continue;

                // update center
                const [centerX, centerY] = this.correctPosition([polygon.bounds.x + polygon.bounds.width * 0.5, polygon.bounds.y + polygon.bounds.height * 0.5]);
                return {
                    x: centerX,
                    y: centerY,
                };
            }
        }

        return {
            x: 0,
            y: 0,
        };
    }

    set floor(value) {
        if (this._floor === value) return;
        this._floor = value;
        this._needsUpdate = true;
    }

    set view(value) {
        if (this._view === value) return;
        this._view = value;
        this._needsUpdate = true;
    }

    set zoom(value) {
        if (this._zoom === value) return;
        this._zoom = value;
        this._needsUpdate = true;

        this._canvas.style.filter = `blur(${1 + this._zoom * 2}px)`;
    }

    get canvas() {
        return this._canvas;
    }
}
