export const expositions = {
    N: 'Nord',
    S: 'Sud',
    E: 'Est',
    O: 'Ouest',
    NS: 'Nord-Sud',
    OE: 'Ouest-Est',
    NO: 'Nord-Ouest',
    NE: 'Nord-Est',
    SO: 'Sud-Ouest',
    SE: 'Sud-Est',
    OSE: 'Ouest-Sud-Est',
    ONE: 'Ouest-Nord-Est',
};

export const options = {
    terrasse: 'Terrasse',
    balcon: 'Balcon',
    loggia: 'Loggia',
    jardin: 'Jardin',
    cellier: 'Cellier',
};
