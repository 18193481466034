import React, { useState, useEffect } from 'react';
import styles from './styles.module';

import MouseUtils from 'util/MouseUtils';

let enabled = true;
let lastIndex = -1;

export default function ({ images, dragThreshold = 100, index, setIndex }) {
    // const [index, setIndex] = useState(0);

    useEffect(() => {
        function prev() {
            enabled = false;
            setIndex((index) => (index <= 0 ? images.length - 1 : index - 1));
        }

        function next() {
            enabled = false;
            setIndex((index) => (index + 1) % images.length);
        }

        function onKeyDown(evt) {
            if (!enabled) return;
            if (evt.keyCode === 37) prev();
            else if (evt.keyCode === 38) prev();
            else if (evt.keyCode === 39) next();
            else if (evt.keyCode === 40) next();
        }

        function onWheel(evt) {
            if (!enabled) return;
            if (evt.deltaY < 0) prev();
            else if (evt.deltaY > 0) next();
        }

        let touching = false;
        let dragging = false;
        let startMouseY;

        function onMouseDown(evt) {
            touching = true;
        }

        function onMouseMove(evt) {
            if (!touching) return;

            const mouseY = MouseUtils.getPosition(evt).y;

            if (touching && !dragging) {
                dragging = true;
                startMouseY = mouseY;
            }

            const diffY = mouseY - startMouseY;
            if (Math.abs(diffY) >= dragThreshold) {
                if (!enabled) return;
                if (diffY > 0) prev();
                else next();
                onMouseUp(null);
            }
        }

        function onMouseUp(evt) {
            touching = false;
            dragging = false;
        }

        document.body.addEventListener('keydown', onKeyDown, false);
        window.addEventListener('wheel', onWheel, false);

        window.addEventListener('mousedown', onMouseDown, false);
        window.addEventListener('touchstart', onMouseDown, false);
        window.addEventListener('mousemove', onMouseMove, false);
        window.addEventListener('touchmove', onMouseMove, false);
        window.addEventListener('mouseup', onMouseUp, false);
        window.addEventListener('touchend', onMouseUp, false);

        return () => {
            document.body.removeEventListener('keydown', onKeyDown, false);
            window.removeEventListener('wheel', onWheel, false);

            window.removeEventListener('mousedown', onMouseDown, false);
            window.removeEventListener('touchstart', onMouseDown, false);
            window.removeEventListener('mousemove', onMouseMove, false);
            window.removeEventListener('touchmove', onMouseMove, false);
            window.removeEventListener('mouseup', onMouseUp, false);
            window.removeEventListener('touchend', onMouseUp, false);
        };
    }, []);

    useEffect(() => {
        if (lastIndex === index) return;
        lastIndex = index;
        enabled = false;
        setTimeout(() => (enabled = true), 400);

        setIndex(index);
    }, [index]);

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                {images.map((url, idx) => (
                    <div key={idx} className={styles.slide + ' ' + (index === idx ? styles.slide_visible : '')} style={{ backgroundImage: `url('${url}')` }}></div>
                ))}
            </div>
        </div>
    );
}
