import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module';

import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
gsap.registerPlugin(Draggable);

export default function ({ min = 0, max = 100, value = 0, step = 1, unit = 'u', onMin, onMax }) {
    const rangeRef = useRef();
    const minRef = useRef();
    const maxRef = useRef();
    const [minValue, setMinValue] = useState(min);
    const [maxValue, setMaxValue] = useState(max);

    useEffect(() => {
        if (!rangeRef.current) return;
        if (!minRef.current) return;
        if (!maxRef.current) return;

        Draggable.create(minRef.current, {
            bounds: rangeRef.current,
            type: 'x',
            liveSnap: {
                x: (value) => {
                    const width = rangeRef.current.clientWidth;

                    const rangeRect = rangeRef.current.getBoundingClientRect();
                    const maxRect = maxRef.current.getBoundingClientRect();

                    const invertValue = value < 0;
                    if (invertValue) value = width + value;

                    const maxRatio = (maxRect.left - rangeRect.left) / rangeRect.width;
                    const maxValue = width * maxRatio - maxRef.current.childNodes[0].clientWidth;
                    value = Math.min(value, maxValue);

                    const ratio = value / width;
                    let val = min + ratio * (max - min);
                    val = step * Math.round(val / step);
                    onMin(val);
                    setMinValue(val);

                    return invertValue ? -width + value : value;
                },
            },
        });

        Draggable.create(maxRef.current, {
            bounds: rangeRef.current,
            type: 'x',
            liveSnap: {
                x: (value) => {
                    const width = rangeRef.current.clientWidth;

                    const rangeRect = rangeRef.current.getBoundingClientRect();
                    const minRect = minRef.current.getBoundingClientRect();

                    const invertValue = value < 0;
                    if (invertValue) value = width + value;

                    const minRatio = (minRect.left - rangeRect.left) / rangeRect.width;
                    const minValue = width * minRatio + maxRef.current.childNodes[0].clientWidth;
                    value = Math.max(value, minValue);

                    const ratio = value / width;
                    let val = min + ratio * (max - min);
                    val = step * Math.round(val / step);
                    onMax(val);
                    setMaxValue(val);

                    return invertValue ? -width + value : value;
                },
            },
        });
    }, [rangeRef.current, minRef.current, maxRef.current]);

    return (
        <div className={styles.root}>
            <div className={styles.labelBefore}>
                {minValue} {unit}
            </div>
            <div className={styles.range} ref={rangeRef}>
                <div className={styles.thumb} ref={minRef}>
                    <div className={styles.thumb_content}></div>
                </div>
                <div className={styles.thumb} style={{ left: '100%' }} ref={maxRef}>
                    <div className={styles.thumb_content}></div>
                </div>
            </div>
            <div className={styles.labelAfter}>
                {maxValue} {unit}
            </div>
        </div>
    );
}
