import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module';
import useWindowSize from 'hooks/useWindowSize';
import * as labels from 'labels';
import MiscUtils from 'util/MiscUtils';
import BtnFavorite from 'components/BtnFavorite';
import Svg from 'components/Svg';
import Carousel from 'components/Carousel';
import { getFloor } from 'hooks/useFloor';
import useDayNight from 'hooks/useDayNight';
import gsap, { Circ, Back } from 'gsap';

import svg360 from '!!raw-loader!./icon_360.svg';
import svgPlan from '!!raw-loader!./icon_plan.svg';
import svgView3d from '!!raw-loader!./icon_view3d.svg';

export default function ({ flat, onClose = null, showBtnFavorite = false, showView = false, autoScale = true, forceParentHeight = 0, onResize = null, viewIndex = 0, viewType = null, toggleViewType = null }) {
    const rootRef = useRef();
    const carouselWrapperRef = useRef();
    const [canvas] = useState(document.createElement('canvas'));
    const [ctx] = useState(canvas.getContext('2d'));
    const windowSize = useWindowSize();
    const [viewVisible, setViewVisible] = useState(false);
    const [dayNight] = useDayNight();

    useEffect(() => {
        if (!rootRef.current) return;
        if (!autoScale) return;

        const root = rootRef.current;
        root.style.transform = '';

        const scaleRatio = window.innerWidth / 1920;

        const width = root.clientWidth;
        root.style.transform = '';
        const height = root.clientHeight;
        const parentHeight = forceParentHeight > 0 ? forceParentHeight : root.parentElement.parentElement.parentElement.clientHeight;

        let scale;
        if (height < parentHeight) scale = 1;
        else scale = Math.min(1, (scaleRatio * parentHeight) / height);

        root.style.transform = `scale(${scale})`;
        root.style.marginRight = `-${width - scale * width}px`;

        onResize && onResize(scale * root.clientHeight, rootRef.current);
    }, [rootRef.current, windowSize, forceParentHeight]);

    useEffect(() => {
        if (!canvas.parentElement) return;

        canvas.width = canvas.parentElement.clientWidth;
        canvas.height = canvas.parentElement.clientHeight;

        window.viewerUtils.viewer._mask.drawMask(ctx, flat, viewIndex);
    }, [windowSize, viewIndex]);

    useEffect(() => {
        if (!carouselWrapperRef.current) return;

        carouselWrapperRef.current.appendChild(canvas);

        canvas.width = canvas.parentElement.clientWidth;
        canvas.height = canvas.parentElement.clientHeight;

        window.viewerUtils.viewer._mask.drawMask(ctx, flat, viewIndex);
    }, [carouselWrapperRef.current]);

    useEffect(() => {
        // animate view icons on appear
        const elts = Array.from(document.getElementsByClassName(styles.block_icon)) as HTMLElement[];

        if (!toggleViewType) {
            elts.forEach((elt) => (elt.style.opacity = '1'));
        } else {
            gsap.to(elts, 0.4, {
                opacity: 1,
                delay: 0.4,
                ease: Circ.easeOut,
                stagger: {
                    each: 0.35,
                },
            });
            gsap.from(elts, 0.4, {
                scale: 0.2,
                delay: 0.4,
                ease: Back.easeOut,
                stagger: {
                    each: 0.35,
                },
            });
        }
    }, []);

    async function getViewImage(index) {
        const floor = getFloor() + 1;
        const image = await window.smartLoader.view_low(floor, index, dayNight);
        return image;
    }

    function toggleViewVisible() {
        setViewVisible((visible) => !visible);
    }

    const has360 = false;
    const hasPlan = flat.hasPlan;
    const hasView3d = flat.hasView3d;
    const hasPlanAndView3d = !!toggleViewType && hasPlan && hasView3d;

    const name = flat.name;
    const type = flat.type.toUpperCase();
    const size = MiscUtils.sizeLabel(flat.size);
    const floor = MiscUtils.floorLabel(flat.floor);
    const exposition = labels.expositions[flat.exposition];
    const price = MiscUtils.priceLabel(flat.price);
    const isSold = window.isFlatSold(flat.name);
    const options = [];
    for (let key in labels.options) {
        if (flat[key]) options.push(labels.options[key]);
    }

    return (
        <div className={styles.root} ref={rootRef} id="blockappt">
            <div className={styles.top}>
                <div className={styles.resume}>
                    <div className={styles.resume_circle}>
                        <div className={styles.resume_circle_name}>
                            {name}
                            {showBtnFavorite && <BtnFavorite flat={flat} className={styles.resume_circle_favorite} />}
                        </div>
                    </div>
                    <div className={styles.resume_infos}>
                        <div className={styles.resume_infos_line1}>
                            <div className={styles.resume_infos_line1_type}>{type}</div>
                            <div className={styles.resume_infos_line1_floor}>{floor}</div>
                        </div>
                        <div className={styles.resume_infos_size}>{size} m²</div>
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>Exposition</div>
                    <div className={styles.block_value}>{exposition}</div>
                    <div className={styles.block_separator}></div>
                    {hasPlanAndView3d && (
                        <div className={styles.block_icon} onClick={toggleViewType}>
                            {viewType === 'plan' && <Svg svg={svgView3d} />}
                            {viewType === 'view3d' && <Svg svg={svgPlan} />}
                        </div>
                    )}
                    {!hasPlanAndView3d && has360 && (
                        <div className={styles.block_icon}>
                            <Svg svg={svgPlan} />
                        </div>
                    )}
                </div>
                <div className={styles.block}>
                    <div className={styles.title}>Disponibilité</div>
                    <div className={styles.block_value}>{window.soldData.indexOf(name) >= 0 ? 'Non disponible' : 'Disponible'}</div>
                    <div className={styles.block_separator}></div>
                    {hasPlanAndView3d && has360 && (
                        <div className={styles.block_icon}>
                            <Svg svg={svg360} />
                        </div>
                    )}
                </div>
                <div className={styles.title}>Options</div>
                <div className={styles.options}>
                    {options.map((option) => (
                        <div key={option} className={styles.option}>
                            <span>{option}</span>
                        </div>
                    ))}
                </div>
                {onClose && (
                    <div className={styles.btnClose} onClick={onClose}>
                        <div className={styles.btnClose_center}>
                            <div className={styles.btnClose_cross}>
                                <div className={styles.btnClose_cross_content}>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showView && (
                    <div className={styles.view + ' ' + (viewVisible ? styles.view_visible : '')}>
                        <div className={styles.view_content}>
                            <div className={styles.view_content_image} ref={carouselWrapperRef}>
                                <Carousel getImage={getViewImage} index={viewIndex} />
                            </div>
                        </div>
                        <div className={styles.view_open} onClick={toggleViewVisible}>
                            <div className={styles.view_open_arrow}></div>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.bottom}>
                {!isSold && <div className={styles.bottom_center}>{price} €</div>}
                {isSold && <div className={styles.bottom_center}>VENDU</div>}
            </div>
        </div>
    );
}
