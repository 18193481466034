import React, { useEffect, useRef } from 'react';
import styles from './styles.module';

export default function ({ degrees = 0, signal = null, visible, rightbar = false }) {
    const rootRef = useRef();

    const classNames = [styles.compass];
    visible && classNames.push(styles.compass_visible);
    rightbar && classNames.push(styles.compass_rightbar);

    useEffect(() => {
        if (!rootRef.current) return;
        rootRef.current.style.transform = `rotate(${degrees}deg)`;
    }, [rootRef.current]);

    useEffect(() => {
        rootRef.current.style.transform = `rotate(${degrees}deg)`;
    }, [degrees]);

    useEffect(() => {
        if (!signal) return;

        const setIndex = (index) => (rootRef.current.style.transform = `rotate(${(360 * index) / 60}deg)`);
        signal.add(setIndex);
        return () => signal.remove(setIndex);
    }, [signal]);

    return (
        <div id="compass" className={classNames.join(' ')} ref={rootRef}>
            <div className={styles.compass_content}></div>
        </div>
    );
}
