import React, { useEffect, useState } from 'react';

function enterFullscreen(docElm = document.body) {
    if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
    } else if (docElm['mozRequestFullScreen']) {
        docElm['mozRequestFullScreen']();
    } else if (docElm['webkitRequestFullScreen']) {
        docElm['webkitRequestFullScreen']();
    } else if (docElm['msRequestFullscreen']) {
        docElm['msRequestFullscreen']();
    }
}

function exitFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document['mozCancelFullScreen']) {
        document['mozCancelFullScreen']();
    } else if (document['webkitExitFullscreen']) {
        document['webkitExitFullscreen']();
    } else if (document['msExitFullscreen']) {
        document['msExitFullscreen']();
    }
}

function isFullscreen() {
    // return document['webkitIsFullScreen'] || document['mozFullScreen'] || document['msFullscreenElement'] || (window.innerWidth / window.screen.width >= 0.95 && window.innerWidth / window.screen.height >= 0.95);
    return document['webkitIsFullScreen'] || document['mozFullScreen'] || document['msFullscreenElement'] || 1 >= window.outerHeight - window.innerHeight;
}

export default function () {
    const [fullscreen, _setFullscreen] = useState(isFullscreen());

    useEffect(() => {
        function fullscreenHandler() {
            setTimeout(() => {
                _setFullscreen(isFullscreen());
            }, 100);
        }

        document.addEventListener('webkitfullscreenchange', fullscreenHandler, false);
        document.addEventListener('mozfullscreenchange', fullscreenHandler, false);
        document.addEventListener('fullscreenchange', fullscreenHandler, false);
        document.addEventListener('MSFullscreenChange', fullscreenHandler, false);
        window.addEventListener('resize', fullscreenHandler, false);

        return () => {
            document.removeEventListener('webkitfullscreenchange', fullscreenHandler, false);
            document.removeEventListener('mozfullscreenchange', fullscreenHandler, false);
            document.removeEventListener('fullscreenchange', fullscreenHandler, false);
            document.removeEventListener('MSFullscreenChange', fullscreenHandler, false);
            window.removeEventListener('resize', fullscreenHandler, false);
        };
    }, []);

    function setFullscreen(value) {
        _setFullscreen(value);

        if (value) {
            enterFullscreen();
        } else {
            exitFullscreen();
        }
    }

    return [fullscreen, setFullscreen];
}
