import React from 'react';
import styles from './styles.module';

export default function({ label, color, selected = false, onClick }) {
    return (
        <div className={styles.root + ' ' + (selected ? styles.root_selected : '')} onClick={onClick}>
            <div style={{ backgroundColor: selected ? color : 'transparent' }}>
                <div>{label.charAt(0)}</div>
                <div>{label.charAt(1)}</div>
            </div>
        </div>
    );
}
