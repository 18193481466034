import React, { useState, useEffect } from 'react';
import Signal from 'util/Signal';

let _floor = 0;
export const onChangeFloor = new Signal();

export function getFloor() {
    return _floor;
}

export function setFloor(value) {
    _floor = value;
    onChangeFloor.emit(value);
}

export default function useFloor() {
    const [floor, _setFloor] = useState(_floor);

    useEffect(() => {
        const id = onChangeFloor.add(_setFloor);
        return () => onChangeFloor.removeId(id);
    }, []);

    return [floor, setFloor];
}
