import { useEffect, useState } from 'react';

export default function() {
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        function onResize() {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        }

        window.addEventListener('resize', onResize, false);

        return () => window.removeEventListener('resize', onResize, false);
    });

    return size;
}
