import UPNG from 'upng-js';

export default class BulkImageLoader {
    _urls = [];
    _current = 0;
    _maxConcurrent = 5;
    _loaded = {};
    _binary = {};
    _wait = false;

    constructor() {}

    async preload(filesData = []) {
        // preloaded data as an array of url/arrayBuffer
        for (let url in filesData) {
            const arrayBuffer = filesData[url];
            const image = await this._createImageFromArrayBuffer(arrayBuffer, url);

            // only keep binary for mask images
            if (url.indexOf('mask') >= 0) this._binary[url] = new Uint8Array(UPNG.toRGBA8(UPNG.decode(arrayBuffer))[0]);

            this._loaded[url] = image;
        }
    }

    // create image from array bufffer
    _createImageFromArrayBuffer(arrayBuffer, url) {
        return new Promise((resolve, reject) => {
            const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'image/png' });
            const image = new Image();

            image.onerror = () => {
                console.log('cannot create image from array buffer', url);
            };
            image.onload = () => resolve(image);

            image.src = (window.URL || window.webkitURL).createObjectURL(blob);
        });
    }

    // load an image
    load(url: string, binary = false) {
        return new Promise((resolve, reject) => {
            this._urls.push({
                url,
                binary,
                resolve,
            });

            this._next();
        });
    }

    // load an image right now (current loading images keep loading)
    priority(url: string, binary) {
        return new Promise((resolve, reject) => {
            this._urls.unshift({
                priority: true,
                url,
                binary,
                resolve,
            });

            this._next();
        });
    }

    // load an image right now (no parallel loading)
    critical(url: string, binary) {
        return new Promise((resolve, reject) => {
            this._urls.unshift({
                critical: true,
                url,
                binary,
                resolve,
            });

            this._next();
        });
    }

    // load an image right now (no parallel loading)
    criticalBinary(url: string, binary) {
        return new Promise((resolve, reject) => {
            this._urls.unshift({
                critical: true,
                url,
                binary: true,
                onlyBinary: true,
                resolve: (binary) => {
                    console.log('resolved', url, binary);
                    resolve(binary);
                },
            });

            this._next();
        });
    }

    // load a batch of urls
    loadBulk(urls: string[], onLoadImage, binary) {
        if (!urls) return;

        return new Promise((resolve, reject) => {
            let left = urls.length;
            for (let i = 0; i < urls.length; i++) {
                const url = urls[i];
                const index = i;

                this.load(url, binary).then((image) => {
                    onLoadImage && onLoadImage(url, image, index);
                    left--;
                    if (left <= 0) resolve();
                });
            }
        });
    }

    // cancel all downloads
    cancel() {
        this._urls = [];
        this._wait = false;
        this._current = 0;
    }

    binary(url) {
        return this._binary[url];
    }

    private async _next() {
        if (this._wait) return;

        while (this._current < this._maxConcurrent) {
            if (this._urls.length <= 0) return;

            this._current++;
            const data = this._urls.shift();

            const onLoad = (img) => {
                this._current--;
                this._loaded[data.url] = img;
                data.resolve(img);
                this._next();
            };

            if (data.critical) {
                // ensure no other image is downloaded at the same time
                this._wait = true;
                onLoad(await this._loadImage(data.url, data.binary, data.onlyBinary));
                this._wait = false;
                this._next();
            } else {
                this._loadImage(data.url, data.binary, data.onlyBinary).then(onLoad);
            }
        }
    }

    private async _loadImage(url: string, binary = false, onlyBinary = false) {
        if (onlyBinary) return await this._loadBinary(url);
        else if (binary) return await this._loadImageAsBinary(url);
        return await this._loadImageAsImage(url);
    }

    private _loadImageAsImage(url: string) {
        return new Promise((resolve, reject) => {
            // image alreaded loaded
            if (this._loaded[url]) {
                resolve(this._loaded[url]);
                return;
            }

            const img = new Image();
            img.onerror = () => {
                console.error('cannot load', url);
                resolve(img);
            };
            img.onload = () => resolve(img);
            img.crossOrigin = 'anonymous';
            img.src = url;
        });
    }

    private _loadImageAsBinary(url) {
        return new Promise((resolve, reject) => {
            // image alreaded loaded
            if (this._loaded[url]) {
                resolve(this._loaded[url]);
                return;
            }

            const that = this;
            const r = new XMLHttpRequest();
            r.open('GET', url, true);
            r.responseType = 'arraybuffer';
            r.onreadystatechange = async function () {
                if (r.readyState != 4 || r.status != 200) return;

                const arrayBuffer = this.response;
                const img = await that._createImageFromArrayBuffer(arrayBuffer, url);
                that._binary[url] = new Uint8Array(UPNG.toRGBA8(UPNG.decode(arrayBuffer))[0]);
                resolve(img);
            };
            r.send(null);
        });
    }

    private _loadBinary(url) {
        return new Promise((resolve, reject) => {
            const that = this;
            const r = new XMLHttpRequest();
            r.open('GET', url, true);
            r.responseType = 'arraybuffer';
            r.onreadystatechange = async function () {
                if (r.readyState != 4 || r.status != 200) return;

                const arrayBuffer = this.response;
                const uint8Array = new Uint8Array(UPNG.toRGBA8(UPNG.decode(arrayBuffer))[0]);
                resolve(uint8Array);
            };
            r.send(null);
        });
    }
}
