import React, { useState, useEffect, useRef } from 'react';

import MouseUtils from 'util/MouseUtils';
import useForceUpdate from 'hooks/useForceUpdate';

export default function ({ getImage = null, index, setIndex = null, controls = false, maxX = -1 }) {
    const canvasRef = useRef();
    const [ctx, setCtx] = useState(null);
    const [image, setImage] = useState(null);
    const [ready, setReady] = useState(false);
    const forceUpdate = useForceUpdate();

    console.log('maxX', maxX);

    // draw image
    if (canvasRef.current && image) {
        const canvas = canvasRef.current;

        const parentSize = getParentSize(canvasRef.current);
        canvas.width = parentSize.width;
        canvas.height = parentSize.height;

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        const scale = Math.min(canvas.width / image.width, canvas.height / image.height);
        let x = canvas.width / 2 - (image.width / 2) * scale;
        const y = canvas.height / 2 - (image.height / 2) * scale;

        // clamp to max x position
        if (maxX > 0 && x + image.width * scale > maxX) {
            x -= Math.max(0, x + image.width * scale - maxX);
        }

        ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
    }

    function getParentSize(elt) {
        return {
            width: elt.parentElement.clientWidth,
            height: elt.parentElement.clientHeight,
        };
    }

    useEffect(() => {
        (async () => {
            setImage(await getImage(index));
        })();
    }, [index, controls]);

    useEffect(() => {
        if (!image) return;
        if (ready) return;

        const canvas = canvasRef.current;
        /*
        for (let i = 0; i < 10; i++) {
            setTimeout(() => {
                forceUpdate();
                const parentSize = getParentSize(canvasRef.current);
                canvas.width = parentSize.width;
                canvas.height = parentSize.height;

                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                const scale = Math.min(canvas.width / image.width, canvas.height / image.height);
                const x = canvas.width / 2 - (image.width / 2) * scale;
                const y = canvas.height / 2 - (image.height / 2) * scale;
                ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
                console.log('draw2', maxX);
            }, 100 * i);
        }
        */
        setReady(true);
    }, [image]);

    useEffect(() => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvasRef.current.getContext('2d');
        setCtx(ctx);

        const parentSize = getParentSize(canvasRef.current);
        canvas.width = parentSize.width;
        canvas.height = parentSize.height;

        (async () => {
            setImage(await getImage(index));
        })();

        // no controls
        if (!controls) return;

        let dragging = false;
        let startX = 0;
        let startIndex;
        let newIndex;

        function onMouseDown(evt) {
            const mousePos = MouseUtils.getPosition(evt);

            dragging = true;
            startX = mousePos.x;

            setIndex((idx) => {
                startIndex = idx;
                newIndex = idx;
                return idx;
            });
        }

        async function onMouseMove(evt) {
            const mousePos = MouseUtils.getPosition(evt);

            if (dragging) {
                const steps = Math.floor((mousePos.x - startX) / 20);
                newIndex = (startIndex - steps) % 60;
                if (newIndex < 0) newIndex = 60 + newIndex;

                setIndex(newIndex);
            }
        }

        function onMouseUp(evt) {
            dragging = false;
            if (typeof newIndex !== 'undefined') setIndex(newIndex);
        }

        canvasRef.current.addEventListener('mousedown', onMouseDown, false);
        canvasRef.current.addEventListener('touchstart', onMouseDown, false);
        window.addEventListener('mousemove', onMouseMove, false);
        window.addEventListener('touchmove', onMouseMove, false);
        window.addEventListener('mouseup', onMouseUp, false);
        window.addEventListener('touchend', onMouseUp, false);

        return () => {
            canvasRef.current.removeEventListener('mousedown', onMouseDown, false);
            canvasRef.current.removeEventListener('touchstart', onMouseDown, false);
            window.removeEventListener('mousemove', onMouseMove, false);
            window.removeEventListener('touchmove', onMouseMove, false);
            window.removeEventListener('mouseup', onMouseUp, false);
            window.removeEventListener('touchend', onMouseUp, false);
        };
    }, [canvasRef.current, controls]);

    return <canvas ref={canvasRef} style={{ cursor: controls ? 'ew-resize' : '' }}></canvas>;
}
