export default class Unpacker {
    _baseUrl: string;
    _name: string;
    _files = [];

    constructor(baseUrl) {
        this._baseUrl = baseUrl;
    }

    async load() {
        const data = await this.getJSON(`${this._baseUrl}data/pack.json`);
        const arrayBuffer = await this.getBinary(`${this._baseUrl}data/pack.bin`);

        // unpack each file
        for (let file of data) {
            const fileArrayBuffer = arrayBuffer.slice(file.start, file.end);
            const url = this._baseUrl + 'data/' + file.path.join('/') + '/' + file.name;
            this._files[url] = fileArrayBuffer;
        }
    }

    getJSON(url): Promise<any> {
        return new Promise((resolve, reject) => {
            const r = new XMLHttpRequest();
            r.open('GET', url, true);
            r.onreadystatechange = function () {
                if (r.readyState != 4 || r.status != 200) return;
                resolve(JSON.parse(r.responseText));
            };
            r.send(null);
        });
    }

    getBinary(url): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const r = new XMLHttpRequest();
            r.open('GET', url, true);
            r.responseType = 'arraybuffer';
            r.onreadystatechange = function () {
                if (r.readyState != 4 || r.status != 200) return;
                resolve(r.response);
            };
            r.send(null);
        });
    }

    get files() {
        return this._files;
    }
}
