import React, { useState } from 'react';
import styles from './styles.module.scss';

import useDayNight from 'hooks/useDayNight';

import svgIconSearch from '!!raw-loader!./icon_search.svg';
import svgIconFilter from '!!raw-loader!./icon_filter.svg';
import svgIconHome from '!!raw-loader!./icon_home.svg';
import svgIconFavorites from '!!raw-loader!./icon_favorites.svg';
import svgIconGallery from '!!raw-loader!./icon_gallery.svg';
import svgWave from '!!raw-loader!./footer_wave.svg';

import Menu from './Menu';
import { useFavorites } from 'hooks/useFavorites';

export default function ({ page, onPage, showMenu = true }) {
    const favorites = useFavorites();
    const [dayNight] = useDayNight();

    function onSearch() {
        onPage('search');
    }

    function onFilter() {
        onPage('filter');
    }

    function onHome() {
        onPage('home');
    }

    function onFavorites() {
        onPage('favorites');
    }

    function onGallery() {
        onPage('gallery');
    }

    return (
        <div className={styles.root}>
            <div className={styles.buttons}>
                <div className={styles.button + ' ' + styles.button_search + ' ' + (page === 'search' ? styles.button_selected : '')} onClick={onSearch}>
                    <div className={styles.button_wave}>
                        {/* <div className={styles.button_wave_image} style={{ backgroundImage: `url(${window.footerWaveImage})` }}></div> */}
                        <div className={styles.button_wave_image} dangerouslySetInnerHTML={{ __html: svgWave }}></div>
                    </div>
                    <div className={styles.button_cursor}>
                        <div className={styles.button_cursor_content}>
                            <div className={styles.button_icon} dangerouslySetInnerHTML={{ __html: svgIconSearch }}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.button + ' ' + styles.button_filter + ' ' + (page === 'filter' ? styles.button_selected : '')} onClick={onFilter}>
                    <div className={styles.button_wave}>
                        {/* <div className={styles.button_wave_image} style={{ backgroundImage: `url(${window.footerWaveImage})` }}></div> */}
                        <div className={styles.button_wave_image} dangerouslySetInnerHTML={{ __html: svgWave }}></div>
                    </div>
                    <div className={styles.button_cursor}>
                        <div className={styles.button_cursor_content}>
                            <div className={styles.button_icon} dangerouslySetInnerHTML={{ __html: svgIconFilter }}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.button + ' ' + styles.button_home + ' ' + (page === 'home' ? styles.button_selected : '')} onClick={onHome}>
                    <div className={styles.button_wave}>
                        {/* <div className={styles.button_wave_image} style={{ backgroundImage: `url(${window.footerWaveImage})` }}></div> */}
                        <div className={styles.button_wave_image} dangerouslySetInnerHTML={{ __html: svgWave }}></div>
                    </div>
                    <div className={styles.button_cursor}>
                        <div className={styles.button_cursor_content}>
                            <div className={styles.button_icon} dangerouslySetInnerHTML={{ __html: svgIconHome }}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.button + ' ' + styles.button_favorites + ' ' + (page === 'favorites' ? styles.button_selected : '')} onClick={onFavorites}>
                    <div className={styles.button_wave}>
                        {/* <div className={styles.button_wave_image} style={{ backgroundImage: `url(${window.footerWaveImage})` }}></div> */}
                        <div className={styles.button_wave_image} dangerouslySetInnerHTML={{ __html: svgWave }}></div>
                    </div>
                    <div className={styles.button_cursor}>
                        <div className={styles.button_cursor_content}>
                            <div className={styles.button_icon} dangerouslySetInnerHTML={{ __html: svgIconFavorites }}></div>
                        </div>
                    </div>
                    <div className={styles.button_count}>
                        <div>{favorites.length}</div>
                    </div>
                </div>
                <div className={styles.button + ' ' + styles.button_gallery + ' ' + (page === 'gallery' ? styles.button_selected : '')} onClick={onGallery}>
                    <div className={styles.button_wave}>
                        {/* <div className={styles.button_wave_image} style={{ backgroundImage: `url(${window.footerWaveImage})` }}></div> */}
                        <div className={styles.button_wave_image} dangerouslySetInnerHTML={{ __html: svgWave }}></div>
                    </div>
                    <div className={styles.button_cursor}>
                        <div className={styles.button_cursor_content}>
                            <div className={styles.button_icon} dangerouslySetInnerHTML={{ __html: svgIconGallery }}></div>
                        </div>
                    </div>
                </div>
            </div>

            <Menu show={showMenu} />
        </div>
    );
}
