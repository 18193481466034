import React, { useState, useEffect, useCallback } from 'react';
import styles from './styles.module';

import Dropdown from './Dropdown';
import Scrollbar from 'components/Scrollbar';
import BtnFavorite from 'components/BtnFavorite';

import useFloor, { setFloor } from 'hooks/useFloor';
import { expositions } from 'labels';
import MiscUtils from 'util/MiscUtils';
import { openFlatPopup } from 'components/App';

let _setFilters;

function Search({ flats, types, onInit, similar }) {
    const [visible, setVisible] = useState(false);
    const [scrollbarUpdate, setScrollbarUpdate] = useState(null);
    const [showTypes, setShowTypes] = useState(false);
    const [showFloors, setShowFloors] = useState(false);
    const [showBathrooms, setShowBathrooms] = useState(false);
    const [showSuns, setShowSuns] = useState(false);
    const [showOutsides, setShowOutsides] = useState(false);
    const [floor] = useFloor();
    const [filters, setFilters] = useState({
        floors: [],
        types: [],
        bathrooms: [],
        suns: [],
        outsides: [],
    });
    const [results, setResults] = useState([]);

    _setFilters = setFilters;

    // collect flats values
    let maxFloor = -1;
    let maxSDB = 0;
    flats.forEach((flat) => {
        if (flat.floor > maxFloor) maxFloor = flat.floor;
        if (flat.sdb > maxSDB) maxSDB = flat.sdb;
    });

    // types
    const typeValues = {};
    Object.keys(types)
        .sort()
        .forEach((typeId: any) => (typeValues[typeId.toUpperCase()] = types[typeId].label));

    // floors
    const floorValues = {};
    for (let i = 0; i <= maxFloor; i++) floorValues[`${i}`] = MiscUtils.floorLabel(i);

    // bathrooms
    const bathroomValues = {};
    for (let i = 1; i <= maxSDB; i++) bathroomValues[`${i}`] = i + '';

    // suns
    const sunValues = Object.assign({}, expositions);
    const foundSuns = {};
    flats.forEach((flat) => (foundSuns[flat.exposition] = true));
    Object.keys(sunValues).forEach((sun) => {
        if (!foundSuns[sun]) delete sunValues[sun];
    });

    // outside
    const outsideValues = {
        terrasse: 'terrasses',
        balcon: 'balcons',
        loggia: 'loggias',
    };

    // show/hide
    useEffect(() => {
        if (visible) {
            const filteredResults = results.filter((flat) => {
                // floor
                if (flat.floor !== floor) return false;

                return true;
            });

            window.viewerUtils.setFlatsVisible(filteredResults);
        } else {
            window.viewerUtils.setFlatsVisible(flats);
        }
    }, [visible]);

    // init
    useEffect(() => {
        onInit({
            show: () => setVisible(true),
            hide: () => setVisible(false),
        });
    }, []);

    // results
    useEffect(() => {
        const filteredResults = results.filter((result) => {
            // floor
            if (result.floor !== floor) return false;

            return true;
        });

        if (visible) window.viewerUtils.setFlatsVisible(filteredResults);
    }, [results, floor]);

    // filter
    useEffect(() => {
        filter();
    }, [filters]);

    // similar flat
    useEffect(() => {
        if (!similar) return;

        const type = similar.type.toUpperCase();
        const floor = similar.floor + '';
        const bathrooms = similar.sdb + '';
        const sun = similar.exposition;
        const outsides = [];
        similar.terrasse && outsides.push('terrasse');
        similar.loggia && outsides.push('loggia');
        similar.balcon && outsides.push('balcon');

        setFilters({
            floors: [],
            // floors: [floor],
            types: [type],
            bathrooms: [bathrooms],
            suns: [sun],
            outsides: outsides,
        });
    }, [similar]);

    const areFiltersEmpty = useCallback(() => {
        for (let key in filters) {
            const values = filters[key];
            if (values && values.length > 0) return false;
        }
        return true;
    }, [filters]);

    function filter() {
        console.log('filter', areFiltersEmpty());

        if (areFiltersEmpty()) {
            setResults([]);
            return;
        }

        const filteredFlats = flats.filter((flat) => {
            // floors (no floor selected == all floors selected)
            const filteredFloors = filters.floors.length <= 0 ? Object.keys(floorValues) : filters.floors;
            if (filteredFloors.indexOf(flat.floor + '') < 0) return false;

            // types (no type selected == all types selected)
            const filteredTypes = filters.types.length <= 0 ? Object.keys(typeValues) : filters.types;
            if (filteredTypes.indexOf(flat.type.toUpperCase()) < 0) return false;

            // bathrooms (filter bathroom = 0 -> all bathrooms selected)
            if (filters.bathrooms > 0) {
                if (filters.bathrooms.indexOf(flat.sdb + '') < 0) return false;
            }

            // suns (no sun selected == all suns selected)
            const filteredSuns = filters.suns.length <= 0 ? Object.keys(sunValues) : filters.suns;
            if (filteredSuns.indexOf(flat.exposition) < 0) return false;

            // outsides
            if (filters.outsides.length > 0) {
                let hasOutsides = false;
                for (let outside of filters.outsides) {
                    if (flat[outside]) hasOutsides = true;
                }
                if (!hasOutsides) return false;
            }

            return true;
        });

        setResults(filteredFlats);
    }

    function showDropdown(type) {
        setShowTypes(false);
        setShowFloors(false);
        setShowBathrooms(false);
        setShowSuns(false);
        setShowOutsides(false);

        if (type === 'types') setShowTypes(!showTypes && true);
        else if (type === 'floors') setShowFloors(!showFloors && true);
        else if (type === 'bathrooms') setShowBathrooms(!showBathrooms && true);
        else if (type === 'suns') setShowSuns(!showSuns && true);
        else if (type === 'outsides') setShowOutsides(!showOutsides && true);
    }

    function setTypes(types) {
        setFilters((filters) => ({
            ...filters,
            types,
        }));
    }

    function setFloors(floors) {
        setFilters((filters) => ({
            ...filters,
            floors,
        }));
    }

    function setBathrooms(bathrooms) {
        setFilters((filters) => ({
            ...filters,
            bathrooms,
        }));
    }

    function setSuns(suns) {
        setFilters((filters) => ({
            ...filters,
            suns,
        }));
    }

    function setOutsides(outsides) {
        setFilters((filters) => ({
            ...filters,
            outsides,
        }));
    }

    function onMouseEnterFlat(flat) {
        window.viewerUtils.viewer._mask.setFlatHover(flat);
        setFloor(flat.floor);
    }

    function onMouseLeaveFlat(flat) {
        window.viewerUtils.viewer._mask.setFlatHover(null);
    }

    scrollbarUpdate && scrollbarUpdate();

    return (
        <div className={styles.root}>
            <Dropdown title={'type'} onClick={() => showDropdown('types')} open={showTypes} onChange={setTypes} values={typeValues} selected={filters.types} />
            <Dropdown title={'étage'} onClick={() => showDropdown('floors')} open={showFloors} onChange={setFloors} values={floorValues} selected={filters.floors} />
            <Dropdown title={'salle de bain'} onClick={() => showDropdown('bathrooms')} open={showBathrooms} onChange={setBathrooms} values={bathroomValues} selected={filters.bathrooms} />
            <Dropdown title={'exposition'} onClick={() => showDropdown('suns')} open={showSuns} onChange={setSuns} values={sunValues} selected={filters.suns} />
            <Dropdown title={'espaces extérieurs'} onClick={() => showDropdown('outsides')} open={showOutsides} onChange={setOutsides} values={outsideValues} selected={filters.outsides} />
            <div className={styles.results}>
                <div className={styles.results_title}>résultats</div>
                <div className={styles.results_list}>
                    <Scrollbar setUpdate={setScrollbarUpdate}>
                        <div className={styles.results_list_wrapper}>
                            {results.map((result) => (
                                <div key={result.name} className={styles.result} onClick={() => openFlatPopup(result)} onMouseEnter={() => onMouseEnterFlat(result)} onMouseLeave={() => onMouseLeaveFlat(result)}>
                                    <div className={styles.result_circle}>
                                        <div className={styles.result_circle_title}>
                                            {result.name}
                                            <BtnFavorite flat={result} className={styles.result_favorite} />
                                        </div>
                                    </div>
                                    <div className={styles.result_infos}>
                                        <div className={styles.result_infos_line1}>
                                            <div className={styles.result_infos_type}>{result.type}</div>
                                            <div className={styles.result_infos_floor}>{MiscUtils.floorLabel(result.floor)}</div>
                                        </div>
                                        <div className={styles.result_infos_size}>{(result.size + '').replace('.', ',')} m²</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Scrollbar>
                    {results.length <= 0 && <div className={styles.noresult}>pas de résultats...<br/>pour le moment</div>}
                </div>
            </div>
        </div>
    );
}

Search.clear = function () {
    _setFilters({
        floors: [],
        types: [],
        bathrooms: [],
        suns: [],
        outsides: [],
    });
};

export default Search;
