import React, { useState } from 'react';
import styles from './styles.module';

interface DropdownProps {
    title: string;
    values: { [key: string]: string };
    selected: string[];
    open: boolean;
    onClick: () => void;
    onChange?: (values: string[]) => void;
}

export default function ({ title, values, selected = [], open, onClick, onChange }: DropdownProps) {
    function select(key) {
        let arr = selected.slice(0);

        const set = new Set<string>(arr);
        if (set.has(key)) set.delete(key);
        else set.add(key);
        arr = Array.from(set.values());

        const keys = Object.keys(values);
        arr.sort((val1, val2) => {
            return keys.indexOf(val1) < keys.indexOf(val2) ? -1 : 1;
        });

        onChange && onChange(arr);
    }

    const label = selected.map((key) => values[key]).join(', ');

    return (
        <div className={styles.root}>
            <div className={styles.title} onClick={onClick}>
                <span>
                    {title}&nbsp;
                    <div className={styles.triangle + ' ' + (open ? styles.triangle_open : '')}></div>
                </span>
            </div>
            {!open && label.trim().length > 0 && <div className={styles.label}>{label}</div>}
            {open && (
                <div className={styles.values}>
                    {Object.keys(values).map((key) => (
                        <div key={key} className={styles.value + ' ' + (selected.indexOf(key) >= 0 ? styles.value_selected : '')} onClick={() => select(key)}>
                            {values[key]}
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.separator}></div>
        </div>
    );
}
