export enum DraggerType {
    X,
    Y,
    XY
}

interface DraggerOptions {
    type?: DraggerType;
    immediate?: boolean;
    onDragStart?: () => void;
    onDragMove?: () => void;
    onDragEnd?: () => void;
}

export default class Dragger {
    private elt: HTMLElement;
    private options: DraggerOptions;

    private touching: boolean = false;
    private dragging: boolean = false;
    private startMousePos;

    private startTop: number;
    private startLeft: number;

    private bOnMouseDown;
    private bOnMouseMove;
    private bOnMouseUp;

    constructor(elt: HTMLElement, options: DraggerOptions) {
        this.elt = elt;
        this.options = Object.assign(
            {},
            {
                type: DraggerType.XY,
                immediate: false
            },
            options
        );

        this.bOnMouseDown = this.onMouseDown.bind(this);
        this.bOnMouseMove = this.onMouseMove.bind(this);
        this.bOnMouseUp = this.onMouseUp.bind(this);

        elt.addEventListener('mousedown', this.bOnMouseDown, false);
        elt.addEventListener('touchstart', this.bOnMouseDown, false);
        window.addEventListener('mousemove', this.bOnMouseMove, false);
        window.addEventListener('touchmove', this.bOnMouseMove, false);
        window.addEventListener('mouseup', this.bOnMouseUp, false);
        window.addEventListener('touchend', this.bOnMouseUp, false);
        window.addEventListener('mouseleave', this.bOnMouseUp, false);
        window.addEventListener('touchleave', this.bOnMouseUp, false);
    }

    kill() {
        this.elt.removeEventListener('mousedown', this.bOnMouseDown, false);
        this.elt.removeEventListener('touchstart', this.bOnMouseDown, false);
        window.removeEventListener('mousemove', this.bOnMouseMove, false);
        window.removeEventListener('touchmove', this.bOnMouseMove, false);
        window.removeEventListener('mouseup', this.bOnMouseUp, false);
        window.removeEventListener('touchend', this.bOnMouseUp, false);
        window.removeEventListener('mouseleave', this.bOnMouseUp, false);
        window.removeEventListener('touchleave', this.bOnMouseUp, false);
    }

    start(evt) {
        this.onMouseDown(evt);
    }

    onMouseDown(evt) {
        this.touching = true;

        if(this.options.immediate) {
            this.onMouseMove(evt);
        }
    }

    onMouseUp(evt) {
        if (this.dragging) {
            this.options.onDragEnd && this.options.onDragEnd();
        }

        this.touching = false;
        this.dragging = false;
    }

    onMouseMove(evt) {
        if (!this.touching) return;

        // first move
        if (!this.dragging) {
            this.dragging = true;
            this.startMousePos = this.getMousePosition(evt);
            this.startLeft = this.elt.offsetLeft;
            this.startTop = this.elt.offsetTop;

            this.options.onDragStart && this.options.onDragStart();
            return;
        }

        const mousePos = this.getMousePosition(evt);
        const diffX = mousePos.x - this.startMousePos.x;
        const diffY = mousePos.y - this.startMousePos.y;

        const left = this.startLeft + diffX;
        const top = this.startTop + diffY;

        switch (this.options.type) {
            case DraggerType.X:
                this.elt.style.left = left + 'px';
                break;
            case DraggerType.Y:
                this.elt.style.top = top + 'px';
                break;
            case DraggerType.XY:
                this.elt.style.left = left + 'px';
                this.elt.style.top = top + 'px';
                break;
        }

        this.options.onDragMove && this.options.onDragMove();
    }

    getMousePosition(event) {
        let eventDoc, doc, body;

        event = event || window.event; // IE-ism

        // If pageX/Y aren't available and clientX/Y are,
        // calculate pageX/Y - logic taken from jQuery.
        // (This is to support old IE)
        if (event.pageX == null && event.clientX != null) {
            eventDoc = (event.target && event.target.ownerDocument) || document;
            doc = eventDoc.documentElement;
            body = eventDoc.body;

            event.pageX = event.clientX + ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) - ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
            event.pageY = event.clientY + ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) - ((doc && doc.clientTop) || (body && body.clientTop) || 0);
        }

        return {
            x: event.pageX,
            y: event.pageY
        };
    }
}
