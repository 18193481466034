import React, { useState } from 'react';
import styles from './styles.module';

import TypeToggle from '../TypeToggle';

interface DropdownProps {
    title: string;
    values: { [key: string]: any };
    onChange?: (values: string[]) => void;
}

export default function ({ title, values, onChange }: DropdownProps) {
    const [selected, setSelected] = useState([]);

    function select(key) {
        setSelected((arr) => {
            const set = new Set<string>(arr);
            if (set.has(key)) set.delete(key);
            else set.add(key);
            arr = Array.from(set.values());

            const keys = Object.keys(values);
            arr.sort((val1, val2) => {
                return keys.indexOf(val1) < keys.indexOf(val2) ? -1 : 1;
            });

            setTimeout(() => onChange && onChange(arr), 0);

            return arr;
        });
    }

    const label = selected.map((key) => values[key].label).join(', ');

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <span>{title}&nbsp;</span>
            </div>
            <div className={styles.values}>
                {Object.keys(values)
                    .sort()
                    .map((key) => (
                        <div key={key}>
                            <TypeToggle selected={selected.indexOf(key) >= 0} onClick={() => select(key)} label={values[key].label} color={values[key].color} />
                        </div>
                    ))}
            </div>
            <div className={styles.separator}></div>
        </div>
    );
}
