import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module';
import BtnFavorite from 'components/BtnFavorite';
import useFlatHover from 'hooks/useFlatHover';

import gsap from 'gsap';

let tween;

export default function ({ onClick }) {
    const rootRef = useRef();
    const [flat, setFlat] = useState(null);
    const [mouseOver, setMouseOver] = useState(false);
    const newFlat = useFlatHover();

    useEffect(() => {
        tween && tween.kill();

        if (!newFlat && !mouseOver) {
            tween = gsap.to(rootRef.current, 0.3, {
                opacity: 0,
                delay: 0.3,
                onComplete: () => {
                    if (rootRef.current) rootRef.current.style.display = 'none';
                },
            });
        } else if (newFlat) {
            setFlat(newFlat);

            if (rootRef.current) {
                rootRef.current.style.display = '';
                rootRef.current.style.opacity = '';
            }
        }
    }, [newFlat, mouseOver]);

    useEffect(() => {
        if (!rootRef.current) return;

        function onEnter() {
            tween && tween.kill();
            if (rootRef.current) {
                rootRef.current.style.display = '';
                rootRef.current.style.opacity = '';
            }

            setMouseOver(true);
        }

        function onLeave() {
            setMouseOver(false);
        }

        const element = rootRef.current;

        element.addEventListener('mouseenter', onEnter, false);
        element.addEventListener('mouseleave', onLeave, false);

        return () => {
            element.removeEventListener('mouseenter', onEnter, false);
            element.removeEventListener('mouseleave', onLeave, false);
        };
    }, [rootRef.current]);

    const isSold = flat && window.isFlatSold(flat.name);

    if (isSold) {
        return (
            <div className={styles.appthover} ref={rootRef} style={{ display: 'none', opacity: 0 }}>
                {!!flat && (
                    <div className={styles.appthover_content}>
                        <div className={styles.circleName} onClick={onClick}>
                            <div className={styles.circleName_content + ' ' + styles.circleName_content_brown}>{flat.name}</div>
                        </div>
                        <div className={styles.appthover_content} onClick={onClick}>
                            <div className={styles.appthover_content_available}>
                                non
                                <br />
                                disponible
                            </div>
                            <div className={styles.appthover_content_separator}></div>
                            <div className={styles.appthover_content_similar}>
                                Offres
                                <br />
                                similaires
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={styles.appthover} ref={rootRef} style={{ display: 'none', opacity: 0 }}>
            {!!flat && (
                <div className={styles.appthover_content}>
                    <BtnFavorite flat={flat} className={styles.favorite} />
                    <div className={styles.circleName} onClick={onClick}>
                        <div className={styles.circleName_content}>{flat.name}</div>
                    </div>
                    <div className={styles.appthover_content} onClick={onClick}>
                        <div className={styles.appthover_content_type}>{flat.type.toUpperCase()}</div>
                        <div className={styles.appthover_content_size}>{flat.size.toString().replace('.', ',')} m&sup2;</div>
                        <div className={styles.appthover_content_separator}></div>
                        <div className={styles.appthover_content_available}>disponible</div>
                    </div>
                </div>
            )}
        </div>
    );
}
