import React, { useState, useEffect, useCallback } from 'react';
import styles from './styles.module';
import BlockAppt from 'components/BlockAppt';
import useWindowSize from 'hooks/useWindowSize';
import FlatCarousel from 'components/FlatCarousel';
import { getViewIndex } from 'components/Viewer';
import Compass from 'components/Compass';

export default function ({ flat, visible }) {
    const windowSize = useWindowSize();
    const [currFlat, setCurrFlat] = useState(flat);
    const [index, setIndex] = useState(getViewIndex());
    const [showType, setShowType] = useState(flat && flat.hasPlan ? 'plan' : 'view3d');
    const [maxX, setMaxX] = useState(window.innerWidth);

    const toggleType = useCallback(() => {
        setIndex(1);
        setShowType(showType === 'plan' ? 'view3d' : 'plan');
    }, [showType]);

    function setViewIndex(newIndex) {
        setIndex((idx) => {
            if (typeof newIndex === 'function') newIndex = newIndex(idx);
            if (idx === newIndex) return idx;
            return newIndex;
        });
    }

    useEffect(() => {
        if (flat === null) return;
        setCurrFlat(flat);
    }, [flat]);

    if (!currFlat) return null;

    // force block size
    // 140 = some space for view
    const scaleRatio = window.innerWidth / 1920;
    const blockHeight = windowSize.height - (100 + 80 + 130) * scaleRatio;

    // clamp x position of carousel with blockAppt x position
    function updateMaxX(h, blockElt) {
        // @ts-ignore
        window.SETMAXX = setMaxX;

        if (blockElt) {
            setTimeout(() => setMaxX(blockElt.getBoundingClientRect().left - 20), 0);
        }
    }

    return (
        <div className={styles.flatviewer}>
            <div className={styles.content}>
                <div className={styles.block}>
                    <BlockAppt flat={currFlat} showView={true} showBtnFavorite={true} forceParentHeight={blockHeight} viewIndex={index} viewType={showType} toggleViewType={toggleType} onResize={updateMaxX} />
                </div>
                <div className={styles.carousel}>
                    <FlatCarousel flat={currFlat} index={index} setIndex={setViewIndex} type={showType} maxX={maxX} />
                </div>
            </div>
            <Compass degrees={(360 * index) / 60} visible={true} />
        </div>
    );
}
