import React from 'react';
import styles from './styles.module';

export default function ({ title, visible = false, onClose, onClear = null, children = null }) {
    return (
        <div className={styles.root + ' ' + (visible ? styles.root_visible : '')}>
            <div className={styles.btnClose} onClick={onClose}>
                <div className={styles.btnClose_content}>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div className={styles.root_content}>
                <div className={styles.title}>
                    {title}
                    {onClear && (
                        <div className={styles.btnClear} onClick={onClear}>
                            <div className={styles.btnClear_content}>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    )}
                </div>
                {children}
            </div>
        </div>
    );
}
