// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__btnfavorite--XknR9 {\n  width: 1.66667vw;\n  height: 1.66667vw;\n  border-radius: 50%;\n  background-color: #caddf6;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n  .styles-module__btnfavorite--XknR9 span {\n    width: 56%;\n    height: 56%; }\n    .styles-module__btnfavorite--XknR9 span path {\n      fill: var(--color-white);\n      transition: fill 0.7s cubic-bezier(0.19, 1, 0.22, 1); }\n  .styles-module__btnfavorite--XknR9:hover svg path {\n    fill: rgba(var(--color-red-rgb), 0.6); }\n  .styles-module__btnfavorite_selected--3OAE8 svg path {\n    fill: var(--color-red) !important; }\n", "",{"version":3,"sources":["styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAE;EACrB;IACE,UAAU;IACV,WAAW,EAAE;IACb;MACE,wBAAwB;MACxB,oDAAoD,EAAE;EAC1D;IACE,qCAAqC,EAAE;EACzC;IACE,iCAAiC,EAAE","file":"styles.module.scss","sourcesContent":[".btnfavorite {\n  width: 1.66667vw;\n  height: 1.66667vw;\n  border-radius: 50%;\n  background-color: #caddf6;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n  .btnfavorite span {\n    width: 56%;\n    height: 56%; }\n    .btnfavorite span path {\n      fill: var(--color-white);\n      transition: fill 0.7s cubic-bezier(0.19, 1, 0.22, 1); }\n  .btnfavorite:hover svg path {\n    fill: rgba(var(--color-red-rgb), 0.6); }\n  .btnfavorite_selected svg path {\n    fill: var(--color-red) !important; }\n"]}]);
// Exports
exports.locals = {
	"btnfavorite": "styles-module__btnfavorite--XknR9",
	"btnfavorite_selected": "styles-module__btnfavorite_selected--3OAE8"
};
module.exports = exports;
