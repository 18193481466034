import React from 'react';
import styles from './styles.module';

export default function(props) {
    const { label, selected, onClick } = props;

    return (
        <div className={styles.root + ' ' + (selected ? styles.root_selected : '')} onClick={onClick}>
            <span>{label}</span>
        </div>
    );
}
