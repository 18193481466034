import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import Signal from 'util/Signal';
import SmartLoader from 'util/SmartLoader';
import Unpacker from 'util/Unpacker';
import ViewerUtils from 'components/Viewer/ViewerUtils';
import './defaultTypes';
import { setFloor } from 'hooks/useFloor';
import { setViewIndex } from 'components/Viewer';

import imgLeftBarWaveUrl from 'components/LeftBar/leftbar_wave.png';
import imgFooterWaveUrl from 'components/Footer/footer_wave.png';
import MiscUtils from 'util/MiscUtils';

window.RAF = new Signal();
requestAnimationFrame(function update() {
    requestAnimationFrame(update);
    window.RAF.emit();
});

const options = {
    terrasse: 'Terrasse',
    balcon: 'Balcon',
    loggia: 'Loggia',
    jardin: 'Jardin',
    sdb: 'Salle de bain',
    cellier: 'Cellier',
    // dressing: 'Dressing',
    // duplex: 'Duplex',
    // sdb2: '2 Sdb',
};

function get(url): Promise<string> {
    return new Promise((resolve, reject) => {
        const r = new XMLHttpRequest();
        r.open('GET', url, true);
        r.onreadystatechange = function () {
            if (r.readyState != 4 || r.status != 200) return;
            resolve(r.responseText);
        };
        r.send(null);
    });
}

(async () => {
    let startAll = Date.now();

    const BASE_URL = window.location.href.substr(0, window.location.href.lastIndexOf('/') + 1);
    window.BASE_URL = BASE_URL;
    // console.log('BASE_URL', BASE_URL);

    // flats
    const flats = JSON.parse(await get(`${BASE_URL}data/flats.json`));

    const types = {};
    flats.forEach((flat) => {
        flat.type = flat.type.toLowerCase();
        types[flat.type] = window.defaultTypes[flat.type];
    });

    // images data
    const imagesData = JSON.parse(await get(`${BASE_URL}data/images.json`));
    window.imagesData = imagesData;

    // initialize to ceil floor
    setFloor(imagesData.nbFloors);

    // initialize view index
    setViewIndex(parseInt(imagesData.startView) || 0);

    // gallery images
    const images = imagesData.gallery.map((file) => `${BASE_URL}data/gallery/${file}`);

    // masks data
    const masksData = JSON.parse(await get(`${BASE_URL}data/masks.json`));
    window.masksData = masksData;

    // sold flats
    window.updateSoldData = async () => {
        const txt = await get(`${BASE_URL}sold.txt?_=${Date.now()}`);
        window.soldData = txt
            .split('\n')
            .map((value) => value.trim().toUpperCase())
            .filter((value) => value && value.length > 0);
    };
    window.isFlatSold = (name) => window.soldData.indexOf(name) >= 0;
    await window.updateSoldData();

    // preload packed data
    let start = Date.now();
    const unpacker = new Unpacker(BASE_URL);
    await unpacker.load();
    console.log('unpack', Date.now() - start);

    // preload necessary images
    start = Date.now();
    window.smartLoader = new SmartLoader(BASE_URL, imagesData, flats);
    await window.smartLoader.preload(unpacker.files);
    console.log('preload', Date.now() - start);

    // global viewer utils
    window.viewerUtils = new ViewerUtils(flats);
    // window.leftBarWaveImage = (await MiscUtils.tintImageUrl(imgLeftBarWaveUrl, imagesData.color_brown2, imagesData.color_light_brown)).toDataURL();
    // window.footerWaveImage = (await MiscUtils.tintImageUrl(imgFooterWaveUrl, imagesData.color_white, imagesData.color_light_brown)).toDataURL();

    // colors
    const root = document.documentElement;
    imagesData.color_black && root.style.setProperty('--color-black', imagesData.color_black);
    imagesData.color_white && root.style.setProperty('--color-white', imagesData.color_white);
    imagesData.color_white && root.style.setProperty('--color-white-rgb', MiscUtils.hexToRgbCss(imagesData.color_white));
    imagesData.color_gray && root.style.setProperty('--color-gray', imagesData.color_gray);
    imagesData.color_blue && root.style.setProperty('--color-blue', imagesData.color_blue);
    imagesData.color_red && root.style.setProperty('--color-red', imagesData.color_red);
    imagesData.color_red && root.style.setProperty('--color-red-rgb', MiscUtils.hexToRgbCss(imagesData.color_red));
    imagesData.color_brown && root.style.setProperty('--color-brown', imagesData.color_brown);
    imagesData.color_brown2 && root.style.setProperty('--color-brown2', imagesData.color_brown2);
    imagesData.color_light_brown && root.style.setProperty('--color-light-brown', imagesData.color_light_brown);

    start = Date.now();
    ReactDOM.render(<App types={types} options={options} flats={flats} images={images} nbFloors={imagesData.nbFloors} />, document.getElementById('app'));
    console.log('render', Date.now() - start);

    console.log('total', Date.now() - startAll);
})();
