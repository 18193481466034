import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module';
import { getFloor, onChangeFloor } from 'hooks/useFloor';
import { getDayNight, onChangeDayNight } from 'hooks/useDayNight';
import useWindowSize from 'hooks/useWindowSize';
import MouseUtils from 'util/MouseUtils';

import WebGLViewer from './WebGLViewer';
import ApptHover from 'components/ApptHover';
import { setFlatHover } from 'hooks/useFlatHover';
import { openFlatPopup, openSearch } from 'components/App';
import Signal from 'util/Signal';

let index = 0;
let floor;
let dayNight;
let zoom = 0;
let webglViewer;

export const onViewIndex = new Signal();

export function getViewIndex() {
    return index;
}

export function setViewIndex(idx) {
    index = idx;
    onViewIndex.emit(index);
}

export default function ({ flats }) {
    floor = getFloor();
    dayNight = getDayNight();

    const rootRef = useRef();
    const canvasWrapperRef = useRef();
    const flatHoverRef = useRef();
    const windowSize = useWindowSize();

    const smartLoader = window.smartLoader;
    const nbImagesPerFloor = smartLoader.nbImagesPerFloor;

    // when used with useState, it slows down a LOT
    // so hacky way around this bug...
    // the goal is to limit the number of renders of this component
    if (!webglViewer) webglViewer = new WebGLViewer(smartLoader, window.defaultTypes, flats);

    window.viewerUtils.viewer = webglViewer;

    async function update() {
        webglViewer.view = index;
    }

    function redraw() {
        webglViewer.zoom = zoom;
    }

    function showFlat() {
        if (window.isFlatSold(window.viewerUtils.flatHover.name)) {
            openSearch(window.viewerUtils.flatHover);
        } else {
            openFlatPopup(window.viewerUtils.flatHover);
        }
    }

    useEffect(() => {
        const canvas = webglViewer.domElement;

        function prev() {
            if (index <= 0) setViewIndex(nbImagesPerFloor - 1);
            else setViewIndex((index -= 1));
            update();
            redraw();
        }

        function next() {
            setViewIndex((index + 1) % nbImagesPerFloor);
            update();
            redraw();
        }

        function zoomIn() {
            zoom = Math.max(0, Math.min(1, zoom + 0.1));
            redraw();
        }

        function zoomOut() {
            zoom = Math.max(0, Math.min(1, zoom - 0.1));
            redraw();
        }

        function onKeyDown(evt) {
            if (evt.keyCode === 37) return prev();
            else if (evt.keyCode === 39) return next();
        }

        function onWheel(evt) {
            if (evt.deltaY < 0) zoomIn();
            else if (evt.deltaY > 0) zoomOut();
        }

        function onMouseMove(evt) {
            const rect = canvas.getBoundingClientRect();
            const mousePos = MouseUtils.getPosition(evt);
            const x = mousePos.x - rect.left;
            const y = mousePos.y - rect.top;

            if (dragging) {
                const steps = Math.floor((mousePos.x - startX) / 20);
                let newIndex = startIndex;
                const nbImages = nbImagesPerFloor;
                newIndex += -steps;
                while (newIndex < 0) newIndex += nbImages;
                newIndex %= nbImages;

                setViewIndex(newIndex);

                update();

                // ensure mouse over a flat is disabled
                window.viewerUtils.updateMouseOver(0, 0, 0);
            } else {
                webglViewer.mouseAt(x, y);
            }

            redraw();
        }

        let startX = 0;
        let startY = 0;
        let startIndex = 0;
        let dragging = false;
        function onMouseDown(evt) {
            const mousePos = MouseUtils.getPosition(evt);
            startX = mousePos.x;
            startY = mousePos.y;

            dragging = true;

            startIndex = index;
        }

        function onMouseUp(evt) {
            dragging = false;

            // mouse nearly didn't move
            const mousePos = MouseUtils.getPosition(evt);
            if (Math.abs(mousePos.x - startX) < 10 && Math.abs(mousePos.y - startY) < 10) {
                if (window.viewerUtils.flatHover) showFlat();
            }
        }

        function changeFloor(value, preload = true) {
            floor = value;

            if (preload) {
                smartLoader.preloadFloor(floor + 1, dayNight);
            }

            webglViewer.floor = floor + 1;

            // update();
            // redraw();
        }

        function changeDayNight(value) {
            dayNight = value;

            webglViewer.dayNight = value;

            update();
            redraw();
        }

        redraw();

        // render loop
        const renderId = window.RAF.add(() => webglViewer.render());

        // change floor
        onChangeFloor.add(changeFloor);
        changeFloor(getFloor());

        // change day/night
        onChangeDayNight.add(changeDayNight);
        changeDayNight(getDayNight());

        // mouse over a flat
        window.viewerUtils.onMouseEnter.add((flat) => {
            const center = window.viewerUtils.getCenter();
            flatHoverRef.current.style.left = center.x + 'px';
            flatHoverRef.current.style.top = center.y + 'px';
            setFlatHover(flat);

            // pointer cursor
            if (rootRef.current) rootRef.current.style.cursor = 'pointer';
        });
        window.viewerUtils.onMouseLeave.add(() => {
            setFlatHover(null);

            // clear cursor
            if (rootRef.current) rootRef.current.style.cursor = '';
        });

        // helpers for footer menu
        window.viewerUtils.zoomIn = zoomIn;
        window.viewerUtils.zoomOut = zoomOut;
        window.viewerUtils.prev = prev;
        window.viewerUtils.next = next;
        window.viewerUtils.changeFloor = changeFloor;

        window.addEventListener('keydown', onKeyDown, false);
        canvas.addEventListener('wheel', onWheel, false);
        canvas.addEventListener('mousedown', onMouseDown, false);
        canvas.addEventListener('touchstart', onMouseDown, false);
        canvas.addEventListener('mousemove', onMouseMove, false);
        canvas.addEventListener('touchmove', onMouseMove, false);
        window.addEventListener('mouseup', onMouseUp, false);
        window.addEventListener('touchend', onMouseUp, false);

        return () => {
            window.removeEventListener('keydown', onKeyDown, false);
            canvas.removeEventListener('wheel', onWheel, false);
            canvas.removeEventListener('mousedown', onMouseDown, false);
            canvas.removeEventListener('touchstart', onMouseDown, false);
            canvas.removeEventListener('mousemove', onMouseMove, false);
            canvas.removeEventListener('touchmove', onMouseMove, false);
            window.removeEventListener('mouseup', onMouseUp, false);
            window.removeEventListener('touchend', onMouseUp, false);

            window.RAF.removeId(renderId);

            onChangeFloor.remove(changeFloor);

            webglViewer.destroy();
        };
    }, []);

    useEffect(() => {
        const rect = rootRef.current.getBoundingClientRect();
        const width = rect.width;
        const height = rect.height;

        webglViewer.resize(width, height);

        redraw();
    }, [windowSize]);

    useEffect(() => {
        if (!canvasWrapperRef.current) return;
        canvasWrapperRef.current.appendChild(webglViewer.domElement);
        canvasWrapperRef.current.appendChild(webglViewer._mask.canvas);
    }, [canvasWrapperRef.current]);

    return (
        <div className={styles.viewer} id="viewer" ref={rootRef}>
            <div className={styles.canvasWrapper} ref={canvasWrapperRef}></div>
            <div className={styles.flatHover} ref={flatHoverRef}>
                <ApptHover onClick={showFlat} />
            </div>
        </div>
    );
}
