import ShaderCanvas from './shader-canvas';
import fragmentShader from './shader.fragment.glsl';
import Frame from './Frame';
import Mask from './Mask';

export default class WebGLViewer {
    width: number;
    height: number;
    _smartLoader;
    _shaderCanvas: ShaderCanvas;
    _view = 0;
    _floor = 0;
    _dayNight = 'day';
    _zoom = 0;
    _frames: Frame[] = [];
    _framesToUpdate = [];
    _mask: Mask;
    // view high preload
    _timeoutHigh;
    _floorHigh;
    _indexHigh;
    _dayNightHigh;

    constructor(smartLoader, types, flats) {
        this._smartLoader = smartLoader;

        this._shaderCanvas = new ShaderCanvas();
        this._shaderCanvas.setShader(fragmentShader);

        for (let i = 0; i < smartLoader.nbImagesPerFloor; i++) {
            this._frames[i] = new Frame(this._shaderCanvas.gl);
        }

        this._mask = new Mask(window.masksData, types, flats);
    }

    destroy() {
        this._frames.forEach((frame) => frame.destroy());
    }

    resize(width, height) {
        this.width = width;
        this.height = height;

        this._shaderCanvas.setSize(width, height);
        this._shaderCanvas.setUniform('u_resolution', [this.width * window.devicePixelRatio, this.height * window.devicePixelRatio]);

        this._mask.resize(width, height);
    }

    render() {
        // update frames if necessary
        if (this._framesToUpdate.length > 0) {
            // find data for current view index
            let priorityData = null;
            this._framesToUpdate.forEach((data) => {
                if (data.index === this._view) {
                    // only keep data with highest level
                    if (!priorityData || data.level > priorityData.level) priorityData = data;
                }
            });

            // we found an update data for current view and with the highest level
            if (priorityData !== null) {
                // remove all update data for current view
                let i = this._framesToUpdate.length;
                while (i-- > 0) {
                    const data = this._framesToUpdate[i];
                    if (data.index === this._view) this._framesToUpdate.splice(i, 1);
                }

                // only keep the one with highest level
                this._framesToUpdate.unshift(priorityData);
            }

            // keep updating frames while we didn't use 15ms
            const start = Date.now();
            do {
                // update one frame
                const data = this._framesToUpdate.shift();
                this._frames[data.index].update(data.image, data.level);
            } while (Date.now() - start < 5 && this._framesToUpdate.length > 0);
        }

        this._shaderCanvas.bindTexture('u_view', this._frames[this._view].texture, 0);
        this._shaderCanvas.render();

        this._mask.zoom = this._zoom;
        this._mask.floor = this._floor;
        this._mask.view = this._view;
        this._mask.update();
    }

    get domElement() {
        return this._shaderCanvas.domElement;
    }

    set floor(value) {
        if (this._floor === value) return;
        this._floor = value;

        this._updateFloor();
    }

    _updateFloor() {
        this.stopPreloadViewHigh();
        this._framesToUpdate = [];

        // update each frame
        const floor = this._floor;
        for (let i = 0; i < this._frames.length; i++) {
            const index = i;
            const frame = this._frames[i];
            const isCurrentView = index === this._view;

            const viewLow = this._smartLoader.view_low(floor, i, this._dayNight);
            const view = this._smartLoader.view(floor, i, this._dayNight);

            frame.resetLevel();

            if (view) {
                // view

                // direct update of current view
                if (isCurrentView) {
                    frame.update(view);
                } else {
                    // others are delayed
                    this._framesToUpdate.push({
                        index,
                        floor,
                        image: view,
                        level: 1,
                    });
                }
            } else {
                // view low

                // direct update of current view
                if (isCurrentView) {
                    frame.update(viewLow);
                } else {
                    // others are delayed (and updated before normal views)
                    this._framesToUpdate.unshift({
                        index,
                        floor,
                        image: viewLow,
                        level: 0,
                    });
                }

                // load view and will be updated later
                this._smartLoader.loadView(floor, index, this._dayNight).then((image) => {
                    this._framesToUpdate.push({
                        index,
                        floor,
                        image,
                        level: 1,
                    });
                });
            }
        }

        this.preloadViewHigh(floor, this._view, this._dayNight);
    }

    set dayNight(value) {
        if (this._dayNight === value) return;
        this._dayNight = value;

        this._updateFloor();
    }

    get view() {
        return this._view;
    }

    set view(value) {
        if (this._view === value) return;
        this._view = value;

        this.preloadViewHigh(this._floor, this._view, this._dayNight);
    }

    preloadViewHigh(floor, index, dayNight) {
        this.stopPreloadViewHigh();

        this._floorHigh = floor;
        this._indexHigh = index;
        this._dayNightHigh = dayNight;

        this._timeoutHigh = setTimeout(async () => {
            if (!this.isCorrectPreloadHigh(floor, index, dayNight)) return;

            this._smartLoader.view_high(floor, index, dayNight).then((image) => {
                if (!this.isCorrectPreloadHigh(floor, index, dayNight)) return;

                // direct update of frame with high quality view
                this._frames[index].update(image, 2);

                // remove all other updates for this view
                let i = this._framesToUpdate.length;
                while (i-- > 0) {
                    const data = this._framesToUpdate[i];
                    if (data.floor === floor && data.index === this._view) this._framesToUpdate.splice(i, 1);
                }
            });
        }, 200);
    }

    stopPreloadViewHigh() {
        this._floorHigh = -1;
        this._indexHigh = -1;
        this._dayNightHigh = '';
        clearTimeout(this._timeoutHigh);
    }

    isCorrectPreloadHigh(floor, index, dayNight) {
        return this._floorHigh === floor && this._indexHigh === index && this._dayNightHigh === dayNight;
    }

    mouseAt(x: number, y: number) {
        this._mask.mouseAt(x, y);
    }

    get zoom() {
        return this._zoom;
    }

    set zoom(value) {
        if (this._zoom === value) return;
        this._zoom = value;
        this._shaderCanvas.setUniform('u_zoom', value);
    }
}
