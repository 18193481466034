import React, { useState, useEffect } from 'react';
import styles from './styles.module';

import Types from './Types';
import RangeDouble from 'components/RangeDouble';
import Options from './Options';
import useFloor from 'hooks/useFloor';

export default function ({ flats, types, options, onInit }) {
    const [selectedTypes, setSelectedTypes] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [floor] = useFloor();
    const [visible, setVisible] = useState(false);

    // compute max size & price for ranges
    const sizeStep = 5;
    const priceStep = 10;
    let range_maxSize = 0;
    let range_maxPrice = 0;
    flats.forEach((flat) => {
        range_maxSize = Math.max(range_maxSize, flat.size || 0);
        range_maxPrice = Math.max(range_maxPrice, flat.price || 0);
    });
    range_maxSize = sizeStep * Math.ceil(range_maxSize / sizeStep);
    range_maxPrice = priceStep * Math.ceil(range_maxPrice / 1000 / priceStep);

    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(range_maxPrice);
    const [minSize, setMinSize] = useState(0);
    const [maxSize, setMaxSize] = useState(range_maxSize);

    useEffect(() => {
        if (visible) {
            const filteredFlats = flats.filter((flat) => {
                // floor
                if (flat.floor !== floor) return false;

                return true;
            });

            if (selectedTypes == null) window.viewerUtils.setFlatsVisible(filteredFlats);
            else filter();
        } else {
            window.viewerUtils.setFlatsVisible(flats);
        }
    }, [visible]);

    function filter() {
        // filter flats
        const filteredFlats = flats.filter((flat) => {
            // floor
            if (flat.floor !== floor) return false;

            // price
            if (flat.price < minPrice * 1000) return false;
            if (flat.price > maxPrice * 1000) return false;

            // size
            if (flat.size < minSize) return false;
            if (flat.size > maxSize) return false;

            // options
            for (let option of selectedOptions) if (!flat[option]) return false;

            // types (no type selected == all types selected)
            const filteredTypes = selectedTypes == null || selectedTypes.length <= 0 ? Object.keys(types) : selectedTypes;
            if (filteredTypes.indexOf(flat.type) < 0) return false;

            return true;
        });

        window.viewerUtils.setFlatsVisible(filteredFlats);
    }

    useEffect(() => {
        if (!visible) return;
        filter();
    }, [floor, selectedTypes, selectedOptions, minPrice, maxPrice, minSize, maxSize]);

    useEffect(() => {
        onInit({
            show: () => setVisible(true),
            hide: () => setVisible(false),
        });
    }, []);

    useEffect(() => {
        if (!selectedTypes) return;

        // no type selected, they are all visibles
        if (selectedTypes.length <= 0) {
            Object.values(types).forEach((type: any) => {
                window.viewerUtils.setTypeVisible(type.index, true);
            });
            return;
        }

        // only show selected types on viewer
        Object.values(types).forEach((type: any) => {
            const visible = selectedTypes.indexOf(type.id) >= 0;
            window.viewerUtils.setTypeVisible(type.index, visible);
        });
    }, [selectedTypes]);

    return (
        <div className={styles.root}>
            <Types title={'type'} values={types} onChange={setSelectedTypes} />
            <div className={styles.title}>superficie</div>
            <RangeDouble min={0} max={range_maxSize} step={sizeStep} unit={'m²'} onMin={setMinSize} onMax={setMaxSize} />
            <div className={styles.title}>budget</div>
            <RangeDouble min={0} max={range_maxPrice} step={priceStep} unit={'k€'} onMin={setMinPrice} onMax={setMaxPrice} />
            <Options title={'options'} values={options} onChange={setSelectedOptions} />
        </div>
    );
}
