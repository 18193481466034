// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__viewer--3LjL0 {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  cursor: ew-resize; }\n\n.styles-module__canvasWrapper--18QWp {\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0; }\n  .styles-module__canvasWrapper--18QWp canvas {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%; }\n    .styles-module__canvasWrapper--18QWp canvas:last-of-type {\n      -webkit-filter: blur(2px);\n              filter: blur(2px); }\n\n.styles-module__flatHover--1Xy7Y {\n  position: absolute;\n  left: 50%;\n  top: 50%; }\n", "",{"version":3,"sources":["styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,iBAAiB,EAAE;;AAErB;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,QAAQ;EACR,SAAS,EAAE;EACX;IACE,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY,EAAE;IACd;MACE,yBAAiB;cAAjB,iBAAiB,EAAE;;AAEzB;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ,EAAE","file":"styles.module.scss","sourcesContent":[".viewer {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  cursor: ew-resize; }\n\n.canvasWrapper {\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0; }\n  .canvasWrapper canvas {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%; }\n    .canvasWrapper canvas:last-of-type {\n      filter: blur(2px); }\n\n.flatHover {\n  position: absolute;\n  left: 50%;\n  top: 50%; }\n"]}]);
// Exports
exports.locals = {
	"viewer": "styles-module__viewer--3LjL0",
	"canvasWrapper": "styles-module__canvasWrapper--18QWp",
	"flatHover": "styles-module__flatHover--1Xy7Y"
};
module.exports = exports;
