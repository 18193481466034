import React, { useState, useEffect } from 'react';
import styles from './styles.module';

export default function({ visible, onClose, children = null }) {
    const [clear, setClear] = useState(false);

    // clear mechanism
    useEffect(() => {
        if (visible) setClear(true);
    }, [visible]);

    if (clear) {
        setTimeout(() => setClear(false), 10);
    }

    return (
        <div className={styles.root + ' ' + (visible ? styles.root_visible : '')}>
            {!clear && <div className={styles.content}>{children}</div>}
            <div className={styles.btnClose} onClick={onClose}>
                <div className={styles.btnClose_content}>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}
