// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__root--RY7H_ {\n  width: 100%;\n  height: 100%;\n  overflow: hidden; }\n\n.styles-module__wrapper--e4I5S {\n  width: 100%;\n  height: 100%;\n  overflow: hidden; }\n\n.styles-module__slide--3788x {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 0;\n  opacity: 0;\n  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center; }\n  .styles-module__slide_visible--1S8WE {\n    z-index: 1;\n    opacity: 1;\n    transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1); }\n", "",{"version":3,"sources":["styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB,EAAE;;AAEpB;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB,EAAE;;AAEpB;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;EACZ,UAAU;EACV,UAAU;EACV,uDAAuD;EACvD,wBAAwB;EACxB,4BAA4B;EAC5B,2BAA2B,EAAE;EAC7B;IACE,UAAU;IACV,UAAU;IACV,uDAAuD,EAAE","file":"styles.module.scss","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n  overflow: hidden; }\n\n.wrapper {\n  width: 100%;\n  height: 100%;\n  overflow: hidden; }\n\n.slide {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 0;\n  opacity: 0;\n  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center; }\n  .slide_visible {\n    z-index: 1;\n    opacity: 1;\n    transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1); }\n"]}]);
// Exports
exports.locals = {
	"root": "styles-module__root--RY7H_",
	"wrapper": "styles-module__wrapper--e4I5S",
	"slide": "styles-module__slide--3788x",
	"slide_visible": "styles-module__slide_visible--1S8WE"
};
module.exports = exports;
