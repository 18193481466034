import React, { useState, useEffect } from 'react';
import Signal from 'util/Signal';

const onFavorites = new Signal();

let favorites = JSON.parse(localStorage.getItem('favorites') || '[]');

function saveFavorites(favs) {
    favorites = favs;
    localStorage.setItem('favorites', JSON.stringify(favorites));
    onFavorites.emit([...favorites]);
}

function addFavorite(flat) {
    const id = getFavoriteId(flat);

    const pos = favorites.indexOf(id);
    if (pos >= 0) return;
    favorites.push(id);
    saveFavorites(favorites);
}

function removeFavorite(flat) {
    const id = getFavoriteId(flat);

    const pos = favorites.indexOf(id);
    if (pos >= 0) favorites.splice(pos, 1);
    saveFavorites(favorites);
}

function toggleFavorite(flat) {
    if (isFavorite(flat)) removeFavorite(flat);
    else addFavorite(flat);
}

function listFavorites(flats) {
    return flats.filter(isFavorite);
}

function isFavorite(flat) {
    return favorites.indexOf(getFavoriteId(flat)) >= 0;
}

function getFavoriteId(flat) {
    return flat.name;
}

function useFavorites() {
    const [favs, setFavs] = useState(favorites);

    useEffect(() => {
        onFavorites.add(setFavs);
        return () => onFavorites.remove(setFavs);
    }, []);

    return favs;
}

export { useFavorites, addFavorite, removeFavorite, listFavorites, toggleFavorite, isFavorite };
