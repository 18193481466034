import React, { useState, useEffect } from 'react';
import Signal from 'util/Signal';

let _dayNight = 'day';
export const onChangeDayNight = new Signal();

export function getDayNight() {
    return _dayNight;
}

function setDayNight(value) {
    _dayNight = value;
    onChangeDayNight.emit(value);
}

export default function useFloor() {
    const [dayNight, _setDayNight] = useState(_dayNight);

    useEffect(() => {
        const id = onChangeDayNight.add(_setDayNight);
        return () => onChangeDayNight.removeId(id);
    }, []);

    return [dayNight, setDayNight];
}
