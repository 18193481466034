import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module';

import svgIconDay from '!!raw-loader!./icon_day.svg';
import svgIconNight from '!!raw-loader!./icon_night.svg';
import svgWave from '!!raw-loader!./leftbar_wave.svg';

import useFloor from 'hooks/useFloor';
import useDayNight from 'hooks/useDayNight';
import gsap, { Expo } from 'gsap';
import Dragger, { DraggerType } from 'util/Dragger';
import useForceUpdate from 'hooks/useForceUpdate';
import useWindowSize from 'hooks/useWindowSize';
import Signal from 'util/Signal';
import useFullscreen from 'hooks/useFullscreen';

interface LeftBarProps {
    nbFloors: number;
}

let isFirst = true;
let tweenFloor = -1;
let tweenCursor;

export const onMouseEnterLeftBar = new Signal();
export const onMouseLeaveLeftBar = new Signal();

export default function (props: LeftBarProps) {
    const forceUpdate = useForceUpdate();
    const windowSize = useWindowSize();
    const fullscreen = useFullscreen();
    const [floor, setFloor] = useFloor();
    const [dayNight, setDayNight] = useDayNight();
    const rootRef = useRef();
    const dragOverlayRef = useRef();
    const cursorRef = useRef();
    const waveRef = useRef();
    const floorsRef = useRef();
    const { nbFloors } = props;
    const [refs] = useState([]);

    useEffect(() => {
        if (!rootRef.current) return;

        function onMouseEnter() {
            onMouseEnterLeftBar.emit();
        }

        function onMouseLeave() {
            onMouseLeaveLeftBar.emit();
        }

        rootRef.current.addEventListener('mouseenter', onMouseEnter, false);
        rootRef.current.addEventListener('mouseleave', onMouseLeave, false);

        return () => {
            rootRef.current.removeEventListener('mouseenter', onMouseEnter, false);
            rootRef.current.removeEventListener('mouseleave', onMouseLeave, false);
        };
    }, [rootRef.current]);

    // floors max height according to number of floors
    useEffect(() => {
        if (!floorsRef.current) return;

        const scaleRatio = window.innerWidth / 1920;
        let floorsMargin = 52 * scaleRatio;
        const floorsShouldHeight = floorsMargin * (nbFloors * 2 - 1) + 20 * nbFloors;
        const floorsRect = floorsRef.current.getBoundingClientRect();

        if (floorsRect.height > floorsShouldHeight) {
            floorsRef.current.style.maxHeight = `${floorsShouldHeight}px`;
        }
    }, [floorsRef.current]);

    const floors = [];
    for (let i = nbFloors - 1; i >= 0; i--) {
        floors.push(
            <div
                key={'floor_' + i}
                className={styles.floor}
                ref={(ref) => {
                    refs[i * 2] = ref;
                    goToFloor(floor);
                }}
                onClick={setFloor.bind(this, i)}
            >
                <div>{getFloorName(i)}</div>
            </div>
        );

        if (i > 0) {
            let style = {
                height: '',
            };
            if (nbFloors > 15) {
                style.height = '0';
            }

            floors.push(
                <div
                    key={'sep_' + i}
                    ref={(ref) => {
                        refs[i * 2 + 1] = ref;
                        goToFloor(floor);
                    }}
                    style={style}
                    className={styles.floor_separator}
                ></div>
            );
        }
    }

    function getFloorName(nb) {
        if (nb === 0) return 'rdc';
        if (nb === nbFloors - 1) return 'toit';
        return nb + '';
    }

    function goToFloor(nb) {
        if (refs.length < nbFloors * 2 - 1) return;
        if (!cursorRef.current) return;
        if (!waveRef.current) return;
        if (!floorsRef.current) return;

        const ref = refs[nb * 2];
        if (!ref) return;

        if (tweenFloor === nb) return;

        let immediate = isFirst;

        tweenFloor = nb;
        isFirst = false;

        const obj = ref;
        const pos = obj.getBoundingClientRect();
        const parentPos = obj.parentElement.getBoundingClientRect();
        const contentTop = floorsRef.current.getBoundingClientRect().top;
        const top = pos.top - parentPos.top + pos.height * 0.5 + contentTop;

        tweenCursor?.kill();
        if (immediate) {
            tweenCursor = gsap.set([cursorRef.current, waveRef.current], {
                top,
                ease: Expo.easeOut,
            });
        } else {
            tweenCursor = gsap.to([cursorRef.current, waveRef.current], 1, {
                top,
                ease: Expo.easeOut,
            });
        }
    }

    useEffect(() => {
        tweenFloor = -1;
    }, [windowSize]);

    useEffect(() => {
        if (!cursorRef.current) return;

        const drag = new Dragger(cursorRef.current, {
            type: DraggerType.Y,
            immediate: true,
            onDragStart: () => {
                dragOverlayRef.current.style.display = 'block';
                tweenCursor?.kill();
            },
            onDragMove: () => {
                const floorsRect = floorsRef.current.getBoundingClientRect();
                const refHeight = refs[0].getBoundingClientRect().height * 0.5;
                const top = Math.max(floorsRect.top + refHeight, Math.min(floorsRect.top + floorsRect.height - refHeight, parseInt(cursorRef.current.style.top)));

                cursorRef.current.style.top = top + 'px';
                waveRef.current.style.top = cursorRef.current.style.top;

                // find closest floor
                let closestFloor = floor;
                let minDistance = 100000;

                const cursorRect = cursorRef.current.getBoundingClientRect();
                const cursorTop = cursorRect.top + cursorRect.height / 2;

                refs.forEach((ref, idx) => {
                    if (!ref) return;
                    if (idx % 2 !== 0) return;
                    const refRect = ref.getBoundingClientRect();
                    const refTop = refRect.top + refRect.height / 2;

                    const distance = Math.abs(refTop - cursorTop) / (cursorRect.height * 0.5);

                    if (distance < minDistance) {
                        minDistance = distance;
                        closestFloor = Math.floor(idx / 2);
                    }
                });

                // update floor during drag
                // no preload of images during drag
                window.viewerUtils.changeFloor(closestFloor, false);

                // tweenFloor = -1;
                // setFloor(closestFloor);
                // forceUpdate();
            },
            onDragEnd: () => {
                dragOverlayRef.current.style.display = '';

                let closestFloor = floor;
                let minDistance = 100000;

                const cursorRect = cursorRef.current.getBoundingClientRect();
                const cursorTop = cursorRect.top + cursorRect.height / 2;

                // find closest floor
                refs.forEach((ref, idx) => {
                    if (!ref) return;
                    if (idx % 2 !== 0) return;
                    const refRect = ref.getBoundingClientRect();
                    const refTop = refRect.top + refRect.height / 2;

                    const distance = Math.abs(refTop - cursorTop) / (cursorRect.height * 0.5);

                    if (distance < minDistance) {
                        minDistance = distance;
                        closestFloor = Math.floor(idx / 2);
                    }
                });

                tweenFloor = -1;
                setFloor(closestFloor);
                forceUpdate();
            },
        });

        return () => drag.kill();
    }, [cursorRef.current]);

    useEffect(() => {
        const rafID = window.RAF.add(() => {
            if (!cursorRef.current) return;
            const cursorRect = cursorRef.current.getBoundingClientRect();
            const cursorTop = cursorRect.top + cursorRect.height / 2;
            const scaleRatio = window.innerWidth / 1920;

            refs.forEach((ref, idx) => {
                if (!ref) return;
                const refRect = ref.getBoundingClientRect();
                const refTop = refRect.top + refRect.height / 2;

                let distance = 1 - Math.max(0, Math.min(1, Math.abs(refTop - cursorTop) / (cursorRect.height * 0.8)));

                // smooth shift
                distance = Math.sin(distance * Math.PI * 0.5);

                const floorIdx = Math.floor(idx / 2);
                const left = -19 * scaleRatio * distance;
                let fontSize = 1.8 + 1.5 * distance * (floorIdx === 0 || floorIdx === nbFloors - 1 ? 0 : 1);

                if (idx === 0 || idx === refs.length - 2) {
                    fontSize *= 0.7;
                }

                ref.style.transform = `translateX(${left}px)`;
                ref.style.fontSize = `${fontSize}rem`;
            });
        });

        return () => window.RAF.removeId(rafID);
    }, []);

    useEffect(() => {
        goToFloor(floor);
    }, [floor]);

    return (
        <div className={styles.root} ref={rootRef}>
            <div className={styles.wave + ' ' + (dayNight === 'night' ? styles.wave_night : '')} ref={waveRef}>
                <span className={styles.wave_svg} dangerouslySetInnerHTML={{ __html: svgWave }}></span>
            </div>
            <div className={styles.dragOverlay} ref={dragOverlayRef}></div>
            <div className={styles.content}>
                <div className={styles.time}>
                    <div className={dayNight === 'day' ? styles.time_selected : ''} onClick={() => setDayNight('day')}>
                        <span dangerouslySetInnerHTML={{ __html: svgIconDay }}></span>
                    </div>
                    <div className={dayNight === 'night' ? styles.time_selected : ''} onClick={() => setDayNight('night')}>
                        <span dangerouslySetInnerHTML={{ __html: svgIconNight }}></span>
                    </div>
                </div>
                <div className={styles.floors} ref={floorsRef}>
                    {floors}
                </div>
            </div>
            <div className={styles.cursor} ref={cursorRef}>
                <div className={styles.cursor_wave}></div>
                <div className={styles.cursor_content}>
                    <div className={styles.cursor_center_hidden + ' ' + (floor === 0 || floor === nbFloors - 1 ? styles.cursor_center_hidden_small : '')}>
                        <span>{getFloorName(floor)}</span>
                    </div>
                    <div className={styles.cursor_center_visible}>
                        <div className={styles.cursor_center_visible_triangle1}></div>
                        <div className={styles.cursor_center_visible_triangle2}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
