import React from 'react';
import styles from './styles.module';

import Svg from 'components/Svg';

import svgStar from '!!raw-loader!./icon_star.svg';
import { isFavorite, toggleFavorite, useFavorites } from 'hooks/useFavorites';

export default function ({ flat, className = null }) {
    const favorites = useFavorites();

    const classNames = [styles.btnfavorite];
    className && classNames.push(className);
    isFavorite(flat) && classNames.push(styles.btnfavorite_selected);

    return (
        <div className={classNames.join(' ')} onClick={() => toggleFavorite(flat)}>
            <Svg svg={svgStar} />
        </div>
    );
}
