import React, { useState, useEffect } from 'react';
import styles from './styles.module';

import svgIconPlus from '!!raw-loader!./icon_plus.svg';
import svgIconMinus from '!!raw-loader!./icon_minus.svg';
import svgIconArrowLeft from '!!raw-loader!./icon_arrowleft.svg';
import svgIconArrowRight from '!!raw-loader!./icon_arrowright.svg';
import svgIconFullscreen from '!!raw-loader!./icon_fullscreen.svg';
import svgIconLock from '!!raw-loader!./icon_lock.svg';
import useFullscreen from 'hooks/useFullscreen';
import { onMouseEnterLeftBar, onMouseLeaveLeftBar } from 'components/LeftBar';
import { openAdmin } from 'components/App';

export default function ({ show = true }) {
    const [open, setOpen] = useState(false);
    const [expand, setExpand] = useState(false);
    const [fullscreen, setFullscreen] = useFullscreen();

    useEffect(() => {
        function onMouseEnter() {
            setExpand(true);
        }

        function onMouseLeave() {
            setExpand(false);
        }

        onMouseEnterLeftBar.add(onMouseEnter);
        onMouseLeaveLeftBar.add(onMouseLeave);

        return () => {
            onMouseEnterLeftBar.remove(onMouseEnter);
            onMouseLeaveLeftBar.remove(onMouseLeave);
        };
    }, []);

    function toggleFullscreen() {
        setFullscreen(!fullscreen);
    }

    function zoomIn() {
        window.viewerUtils.zoomIn();
    }

    function zoomOut() {
        window.viewerUtils.zoomOut();
    }

    function prev() {
        window.viewerUtils.prev();
    }

    function next() {
        window.viewerUtils.next();
    }

    function admin() {
        openAdmin();
    }

    const classNames = [styles.root];
    !show && classNames.push(styles.root_hidden);
    expand && classNames.push(styles.root_expand);

    return (
        <div className={classNames.join(' ')}>
            <div className={styles.btnOpen + ' ' + (open ? styles.btnOpen_open : '')} onClick={() => setOpen(!open)}>
                <div className={styles.btnOpen_center}>
                    <div className={styles.btnOpen_bar1}></div>
                    <div className={styles.btnOpen_bar2}></div>
                    <div className={styles.btnOpen_bar3}></div>
                </div>
            </div>
            <div className={styles.buttons + ' ' + (open ? styles.buttons_open : '')}>
                <div className={styles.button} onClick={zoomIn}>
                    <div className={styles.button_wrapper}>
                        <div className={styles.button_icon} dangerouslySetInnerHTML={{ __html: svgIconPlus }}></div>
                    </div>
                </div>
                <div className={styles.button} onClick={zoomOut}>
                    <div className={styles.button_wrapper}>
                        <div className={styles.button_icon} dangerouslySetInnerHTML={{ __html: svgIconMinus }}></div>
                    </div>
                </div>
                <div className={styles.button} onClick={prev}>
                    <div className={styles.button_wrapper}>
                        <div className={styles.button_icon} dangerouslySetInnerHTML={{ __html: svgIconArrowLeft }}></div>
                    </div>
                </div>
                <div className={styles.button} onClick={next}>
                    <div className={styles.button_wrapper}>
                        <div className={styles.button_icon} dangerouslySetInnerHTML={{ __html: svgIconArrowRight }}></div>
                    </div>
                </div>
                <div className={styles.button} onClick={toggleFullscreen}>
                    <div className={styles.button_wrapper}>
                        <div className={styles.button_icon + ' ' + styles.button_icon_fullscreen + ' ' + (fullscreen ? styles.button_icon_fullscreen_ok : '')} dangerouslySetInnerHTML={{ __html: svgIconFullscreen }}></div>
                    </div>
                </div>
                <div className={styles.button} onClick={admin}>
                    <div className={styles.button_wrapper}>
                        <div className={styles.button_icon + ' ' + styles.button_icon_lock} dangerouslySetInnerHTML={{ __html: svgIconLock }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
