import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module';
import useWindowSize from 'hooks/useWindowSize';

import Carousel from './Carousel';

export default function({ images }) {
    const size = useWindowSize();
    const sliderRef = useRef();
    const [slideIndex, setSlideIndex] = useState(0);
    const scaleRatio = window.innerWidth / 1920;
    const [sliderSize, setSliderSize] = useState({
        width: 0,
        height: 0
    });

    useEffect(() => {
        if (!sliderRef.current) return;

        setSliderSize({
            width: sliderRef.current.clientWidth,
            height: sliderRef.current.clientHeight
        });
    }, [sliderRef.current]);

    return (
        <div className={styles.root}>
            <div className={styles.titleWrapper}>
                <div className={styles.title}>galerie</div>
            </div>
            <div className={styles.content}>
                <div className={styles.slider} ref={sliderRef}>
                    <Carousel images={images} index={slideIndex} setIndex={setSlideIndex} />
                </div>
                <div className={styles.dots} style={{ maxHeight: (images.length - 1) * scaleRatio * 60 + 'px' }}>
                    {images.map((url, idx) => (
                        <div key={idx} className={styles.dot + ' ' + (slideIndex === idx ? styles.dot_selected : '')} onClick={() => setSlideIndex(idx)}>
                            <div className={styles.dot_center}></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
