import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './styles.module';

import Carousel from 'components/Carousel';

export default function ({ flat, index, setIndex, type, maxX }) {
    const rootRef = useRef();

    useEffect(() => {
        window.smartLoader.loadFlat(flat.name);
        window.smartLoader.flat(flat.name, type, index);
    }, [type]);

    const getImage = useCallback(
        (index) => {
            return window.smartLoader.flat(flat.name, type, index);
        },
        [type]
    );

    return (
        <div className={styles.flatcarousel} ref={rootRef}>
            <Carousel getImage={getImage} index={index} setIndex={setIndex} controls={type === 'view3d'} maxX={maxX} />
        </div>
    );
}
