export default class MouseUtils {
    static getPosition(event) {
        if (event.touches) {
            event.pageX = event.touches[0].pageX;
            event.pageY = event.touches[0].pageY;
        } else if (event.pageX == null && event.clientX != null) {
            const eventDoc = (event.target && event.target.ownerDocument) || document;
            const doc = eventDoc.documentElement;
            const body = eventDoc.body;

            event.pageX = event.clientX + ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) - ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
            event.pageY = event.clientY + ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) - ((doc && doc.clientTop) || (body && body.clientTop) || 0);
        }

        return {
            x: event.pageX,
            y: event.pageY
        };
    }
}
