import React, { useState, useEffect } from 'react';
import Signal from 'util/Signal';

let _floor = 0;
const onFlatHover = new Signal();

export function setFlatHover(value) {
    _floor = value;
    onFlatHover.emit(value);
}

export default function useFloor() {
    const [flatHover, _setFlatHover] = useState(_floor);

    useEffect(() => {
        const id = onFlatHover.add(_setFlatHover);
        return () => onFlatHover.removeId(id);
    }, []);

    return flatHover;
}
