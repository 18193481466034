import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module';

import Dragger, { DraggerType } from 'util/Dragger';
import SmoothScrollbar from 'smooth-scrollbar';
import useWindowSize from 'hooks/useWindowSize';

export default function ({ children = null, setUpdate = null }) {
    const rootRef = useRef();
    const scrollThumbRef = useRef();
    const windowSize = useWindowSize();

    useEffect(() => {
        if (!rootRef.current) return;
        if (!scrollThumbRef.current) return;

        const scrollbar = SmoothScrollbar.init(rootRef.current);
        scrollbar.addListener((status) => {
            const scrollTop = scrollbar.scrollTop;
            const contentHeight = rootRef.current.childNodes[0].childNodes[0].clientHeight;
            const containerHeight = rootRef.current.clientHeight;
            const scrollRatio = scrollTop / (contentHeight - containerHeight);

            scrollThumbRef.current.style.top = scrollRatio * 100 + '%';
        });

        new Dragger(scrollThumbRef.current, {
            type: DraggerType.Y,
            onDragMove: () => {
                let top = parseInt(scrollThumbRef.current.style.top);
                const maxTop = scrollThumbRef.current.parentElement.clientHeight;

                // clamp inside container
                top = Math.max(0, Math.min(maxTop, top));

                // update scroll position
                scrollThumbRef.current.style.top = top + 'px';

                scrollbar.scrollTop = scrollbar.limit.y * (top / maxTop);
            },
        });

        function update() {
            setTimeout(() => {
                scrollbar.update();
                scrollThumbRef.current.style.display = scrollbar.size.container.height > scrollbar.size.content.height ? 'none' : '';
            }, 50);
        }
        update();

        setUpdate && setUpdate(() => update);
    }, [rootRef.current, scrollThumbRef.current, windowSize]);

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%', flex: 1 }}>
            <div ref={rootRef} style={{ width: '100%', height: '100%' }}>
                {children}
            </div>
            <div className={styles.scroll_thumb} ref={scrollThumbRef}></div>
        </div>
    );
}
