import WebGLViewer from './WebGLViewer';
import Signal from 'util/Signal';

export default class ViewerUtils {
    flats;
    viewer: WebGLViewer;
    onMouseEnter = new Signal();
    onMouseLeave = new Signal();
    flatHover;
    centerX = 0;
    centerY = 0;

    constructor(flats) {
        this.flats = flats;
    }

    setTypeVisible(index: number, visible: boolean) {
        // this.viewer._maskType.setTypeVisible(index - 1, visible);
    }

    setFlatsVisible(flats) {
        this.viewer._mask.setFlatsVisible(flats);
    }

    updateMouseOver(flat) {
        if (this.flatHover === flat) return;

        this.flatHover = flat;

        if (flat) {
            const center = this.viewer._mask.getCenter(flat);
            this.centerX = center.x;
            this.centerY = center.y;
        }

        if (flat) this.onMouseEnter.emit(flat);
        else this.onMouseLeave.emit();
    }

    getCenter(floor, index) {
        return {
            x: this.centerX,
            y: this.centerY,
        };
    }
}
