import React from 'react';

export default class MiscUtils {
    static floorLabel(value) {
        if (value === 0) return 'RDC';
        return 'R + ' + value;
    }

    static priceLabel(value) {
        return value.toLocaleString();
    }

    static sizeLabel(value) {
        return value.toLocaleString();
    }

    static splitNewline(text, cb = (part, idx) => <div key={idx}>{part}</div>) {
        return text.split('\n').map((part, idx) => cb(part, idx));
    }

    static tintImageUrl(imageUrl, color, backColor): Promise<HTMLCanvasElement> {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            const image = new Image();
            image.crossOrigin = 'anonymous';
            image.onload = function () {
                canvas.width = image.width;
                canvas.height = image.height;

                context.fillStyle = color;
                context.fillRect(0, 0, context.canvas.width, context.canvas.height);
                context.globalCompositeOperation = 'destination-atop';
                context.drawImage(image, 0, 0);

                const canvas2 = document.createElement('canvas');
                const context2 = canvas2.getContext('2d');
                canvas2.width = image.width;
                canvas2.height = image.height;
                context2.fillStyle = backColor;
                context2.fillRect(0, 0, context.canvas.width, context.canvas.height);
                context2.drawImage(canvas, 0, 0);

                resolve(canvas2);
            };
            image.src = imageUrl;
        });
    }

    static hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
    }

    static hexToRgbCss(hex) {
        const color = this.hexToRgb(hex);
        return `${color.r}, ${color.g}, ${color.b}`;
    }
}
