const defaultTypes = {
    t1: {
        index: 1,
        id: 't1',
        label: 'T1',
        color: '#534573',
        r: 83,
        g: 69,
        b: 115,
    },
    t2: {
        index: 2,
        id: 't2',
        label: 'T2',
        color: '#419FD9',
        r: 65,
        g: 159,
        b: 217,
    },
    t3: {
        index: 3,
        id: 't3',
        label: 'T3',
        color: '#4C7359',
        r: 76,
        g: 115,
        b: 89,
    },
    t4: {
        index: 4,
        id: 't4',
        label: 'T4',
        color: '#F2C0A2',
        r: 242,
        g: 192,
        b: 162,
    },
    t5: {
        index: 5,
        id: 't5',
        label: 'T5',
        color: '#F24738',
        r: 242,
        g: 71,
        b: 56,
    },
    t6: {
        index: 6,
        id: 't6',
        label: 'T6',
        color: '#F2B438',
        r: 242,
        g: 180,
        b: 56,
    },
    t7: {
        index: 7,
        id: 't7',
        label: 'T7',
        color: '#CC38F2',
        r: 204,
        g: 56,
        b: 242,
    },
};

window.defaultTypes = defaultTypes;
export default defaultTypes;
