// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".scrollbar-track-y {\n  right: auto;\n  left: 0;\n  opacity: 1 !important;\n  background-color: #fff !important;\n  width: 1px !important; }\n\n.scrollbar-thumb-y {\n  display: none; }\n\n.styles-module__scroll_thumb--1ScXH {\n  position: absolute;\n  z-index: 1;\n  left: 0;\n  top: 0;\n  transform: translate3d(-50%, -50%, 0);\n  cursor: pointer;\n  width: 0.9375vw;\n  height: 0.9375vw;\n  background-color: var(--color-red);\n  border-radius: 50%; }\n", "",{"version":3,"sources":["styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,OAAO;EACP,qBAAqB;EACrB,iCAAiC;EACjC,qBAAqB,EAAE;;AAEzB;EACE,aAAa,EAAE;;AAEjB;EACE,kBAAkB;EAClB,UAAU;EACV,OAAO;EACP,MAAM;EACN,qCAAqC;EACrC,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,kCAAkC;EAClC,kBAAkB,EAAE","file":"styles.module.scss","sourcesContent":[":global .scrollbar-track-y {\n  right: auto;\n  left: 0;\n  opacity: 1 !important;\n  background-color: #fff !important;\n  width: 1px !important; }\n\n:global .scrollbar-thumb-y {\n  display: none; }\n\n.scroll_thumb {\n  position: absolute;\n  z-index: 1;\n  left: 0;\n  top: 0;\n  transform: translate3d(-50%, -50%, 0);\n  cursor: pointer;\n  width: 0.9375vw;\n  height: 0.9375vw;\n  background-color: var(--color-red);\n  border-radius: 50%; }\n"]}]);
// Exports
exports.locals = {
	"scroll_thumb": "styles-module__scroll_thumb--1ScXH"
};
module.exports = exports;
