// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__root--BPrw0 {\n  flex: 1;\n  box-sizing: border-box;\n  display: flex;\n  flex-flow: column nowrap; }\n\n.styles-module__title--1r_5S {\n  margin-bottom: 0.625vw;\n  font-family: futura-pt;\n  font-weight: 700;\n  font-style: normal;\n  font-size: 1.3rem;\n  letter-spacing: 0.2rem;\n  color: var(--color-white);\n  text-transform: uppercase; }\n", "",{"version":3,"sources":["styles.module.scss"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,sBAAsB;EACtB,aAAa;EACb,wBAAwB,EAAE;;AAE5B;EACE,sBAAsB;EACtB,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,yBAAyB;EACzB,yBAAyB,EAAE","file":"styles.module.scss","sourcesContent":[".root {\n  flex: 1;\n  box-sizing: border-box;\n  display: flex;\n  flex-flow: column nowrap; }\n\n.title {\n  margin-bottom: 0.625vw;\n  font-family: futura-pt;\n  font-weight: 700;\n  font-style: normal;\n  font-size: 1.3rem;\n  letter-spacing: 0.2rem;\n  color: var(--color-white);\n  text-transform: uppercase; }\n"]}]);
// Exports
exports.locals = {
	"root": "styles-module__root--BPrw0",
	"title": "styles-module__title--1r_5S"
};
module.exports = exports;
