// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__iframe--3xWvW {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  padding: 0;\n  border: none;\n  background-color: var(--color-light-brown); }\n", "",{"version":3,"sources":["styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,UAAU;EACV,YAAY;EACZ,0CAA0C,EAAE","file":"styles.module.scss","sourcesContent":[".iframe {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  padding: 0;\n  border: none;\n  background-color: var(--color-light-brown); }\n"]}]);
// Exports
exports.locals = {
	"iframe": "styles-module__iframe--3xWvW"
};
module.exports = exports;
