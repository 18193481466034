import React, { useState, useEffect } from 'react';

import LeftBar from './LeftBar';
import Footer from './Footer';
import RightBar from './RightBar';
import Search from './Search';
import Filters from './Filters';
import Popup from './Popup';
import Favorites from './Favorites';
import Gallery from './Gallery';
import ViewerWrapper from './ViewerWrapper';
import Viewer, { getViewIndex, onViewIndex } from './Viewer';
import FlatViewer from './FlatViewer';
import Compass from './Compass';
import Admin from './Admin';

import gsap, { Expo } from 'gsap';
import Address from './Address';

let _setFlatPopup;
export function openFlatPopup(flat) {
    _setFlatPopup(flat);
    hideViewer();
}

let _setShowAdmin;
export function openAdmin() {
    _setShowAdmin(true);
    hideViewer();
}

let _onPage;
export function openSearch(flat) {
    _onPage('search', flat);
}

// dirty trick to show/hide viewer while popups are visible
// prevent flickering when going from one popup to another
let tweenViewer;
function hideViewer() {
    if (tweenViewer) return;
    tweenViewer = gsap.to(document.getElementById('viewer'), 0.4, {
        opacity: 0,
    });
}

function showViewer() {
    tweenViewer && tweenViewer.kill();
    tweenViewer = null;
    document.getElementById('viewer').style.opacity = '1';
}

export default function ({ types, options, flats, images, nbFloors }) {
    const [page, setPage] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);
    const [showGallery, setShowGallery] = useState(false);
    const [flatPopup, setFlatPopup] = useState(null);
    const [showAdmin, setShowAdmin] = useState(false);
    const [fnsFilters, setFnsFilters] = useState(null);
    const [fnsSearch, setFnsSearch] = useState(null);
    const [searchSimilar, setSearchSimilar] = useState(null);

    _setFlatPopup = setFlatPopup;
    _onPage = onPage;
    _setShowAdmin = setShowAdmin;

    useEffect(() => {
        onPage('home');

        // hide loader
        gsap.delayedCall(0.5, () => {
            const $loader = document.getElementById('loader');
            $loader.style.pointerEvents = 'none';
            gsap.to($loader, 1, {
                opacity: 0,
                ease: Expo.easeOut,
                onComplete: () => $loader.remove(),
            });
        });

        console.log('ready');
    }, []);

    // escape to close popups
    useEffect(() => {
        function onKeyDown(evt) {
            if (evt.keyCode !== 27) return;
            if (flatPopup) return closeFlatViewer();
            onPage('home');
        }

        window.addEventListener('keydown', onKeyDown, false);

        return () => window.removeEventListener('keydown', onKeyDown, false);
    }, []);

    function onPage(page, similarFlat = null) {
        setPage(page);
        setShowSearch(false);
        setShowFilter(false);
        setShowFavorites(false);
        setShowGallery(false);
        closeFlatViewer();
        closeAdmin();
        setSearchSimilar(similarFlat);

        let filterWillShow = false;
        let searchWillShow = false;

        switch (page) {
            case 'search':
                setShowSearch(true);
                searchWillShow = true;
                showViewer();
                break;
            case 'filter':
                setShowFilter(true);
                filterWillShow = true;
                showViewer();
                break;
            case 'favorites':
                setShowFavorites(true);
                hideViewer();
                break;
            case 'gallery':
                setShowGallery(true);
                hideViewer();
                break;
            case 'home':
                showViewer();
                break;
            default:
                showViewer();
                console.log('unknown page:', page);
        }

        // show delay to ensure hide functions are called first
        setFnsFilters((fns) => {
            if (!fns) return null;
            if (filterWillShow) setTimeout(() => fns.show(), 200);
            else setTimeout(() => fns.hide(), 0);
            return fns;
        });

        setFnsSearch((fns) => {
            if (!fns) return null;
            if (searchWillShow) setTimeout(() => fns.show(), 200);
            else setTimeout(() => fns.hide(), 0);
            return fns;
        });
    }

    function closeFlatViewer() {
        setFlatPopup(null);
    }

    function closeFlatViewerFromInsideFlatViewer() {
        closeFlatViewer();
        showViewer();
    }

    function closeAdmin() {
        window.updateSoldData();
        setShowAdmin(false);
    }

    function closeAdminFromInsideAdmin() {
        closeAdmin();
        showViewer();
    }

    const showFlatPopup = !!flatPopup;
    const showFooterMenu = !showFlatPopup && !showFavorites && !showGallery;

    const title = window.imagesData.title || '';
    const address = window.imagesData.address || '';
    const logoURL = `${window.BASE_URL}data/logo.png`;

    return (
        <div>
            <ViewerWrapper>
                <Viewer flats={flats} />
            </ViewerWrapper>
            <Compass visible={!showFavorites && !showGallery} rightbar={showFilter || showSearch} signal={onViewIndex} />
            <LeftBar nbFloors={nbFloors+1} />
            <RightBar title={'recherche'} visible={showSearch} onClose={() => onPage('home')} onClear={Search.clear}>
                <Search flats={flats} types={types} onInit={setFnsSearch} similar={searchSimilar} />
            </RightBar>
            <RightBar title={'filtres'} visible={showFilter} onClose={() => onPage('home')}>
                <Filters flats={flats} types={types} options={options} onInit={setFnsFilters} />
            </RightBar>
            <Address visible={!showFavorites && !showGallery} title={title} address={address} logoURL={logoURL} />
            <Popup visible={showFlatPopup} onClose={closeFlatViewerFromInsideFlatViewer}>
                <FlatViewer flat={flatPopup} visible={showFlatPopup} />
            </Popup>
            <Popup visible={showFavorites} onClose={() => onPage('home')}>
                <Favorites flats={flats} />
            </Popup>
            <Popup visible={showGallery} onClose={() => onPage('home')}>
                <Gallery images={images} />
            </Popup>
            <Footer page={page} onPage={onPage} showMenu={showFooterMenu} />
            <Popup visible={showAdmin} onClose={closeAdminFromInsideAdmin}>
                <Admin />
            </Popup>
        </div>
    );
}
