import BulkImageLoader from './BulkImageLoader';

export default class SmartLoader {
    nbImagesPerFloor: number;
    _nbFloors: number;
    _bulkImageLoader = null;
    _imagesData = null;
    _flatsData = null;
    _images = null;
    _floorPreloading: number;
    _pixelImage;

    constructor(baseURL, imagesData, flatsData) {
        this.nbImagesPerFloor = imagesData.view_day['1'].length;
        this._nbFloors = imagesData.nbFloors;
        this._bulkImageLoader = new BulkImageLoader();
        this._imagesData = imagesData;
        this._flatsData = flatsData;
        this._images = {};

        // pixel image when no flat image is found
        this._pixelImage = document.createElement('img');
        this._pixelImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

        const types = ['view_day', 'view_day_low', 'view_day_high', 'view_night', 'view_night_low', 'view_night_high'];

        // prepare images data
        types.forEach((type) => {
            this._images[type] = {};
            for (let floor = 1; floor <= this._nbFloors + 1; floor++) {
                this._images[type][floor] = {};
            }
        });

        for (let floor = 1; floor <= this._nbFloors + 1; floor++) {
            types.forEach((type) => {
                // prepare urls
                for (let index = 0; index < this.nbImagesPerFloor; index++) {
                    const floorData = this._imagesData[type][floor];

                    // only views for ceil (last floor)
                    if (!floorData) continue;
                    const filename = floorData[index];

                    this._imagesData[type][floor][index] = `${baseURL}data/${type}/${floor}/${filename}`;
                }
            });
        }

        // flats images
        this._images.flats = [];
        for (let flatName in this._imagesData.flats) {
            const flat = this._imagesData.flats[flatName];

            this._images.flats[flatName] = {};

            (flat.plan || []).forEach((filename, index) => {
                this._imagesData.flats[flatName].plan[index] = `${baseURL}data/flats/${flatName}/plan/${filename}`;
                this._images.flats[flatName].plan = [];
            });

            (flat.view3d || []).forEach((filename, index) => {
                this._imagesData.flats[flatName].view3d[index] = `${baseURL}data/flats/${flatName}/view3d/${filename}`;
                this._images.flats[flatName].view3d = [];
            });
        }

        console.log(this._imagesData);
    }

    // preload all low resolution views
    async preload(preloadedFiles = []) {
        await this._bulkImageLoader.preload(preloadedFiles);

        for (let floor = 1; floor <= this._nbFloors + 1; floor++) {
            // view day low
            await this._bulkImageLoader.loadBulk(this._imagesData.view_day_low[floor], (url, image, index) => {
                this._images.view_day_low[floor][index] = image;
            });

            // view night low
            await this._bulkImageLoader.loadBulk(this._imagesData.view_night_low[floor], (url, image, index) => {
                this._images.view_night_low[floor][index] = image;
            });

            await this.loadView(1, 0, 'day');
        }
    }

    // preload a floor (except high resolution views)
    async preloadFloor(floor, dayNight, preloadOtherFloors = true) {
        if (this._floorPreloading === floor) return;

        // root preloadFloor
        if (preloadOtherFloors) {
            this._floorPreloading = floor;

            // cancel current images preloading
            this._bulkImageLoader.cancel();
        }

        // prioritize dayNight
        if (dayNight === 'day') {
            // view day
            await this._bulkImageLoader.loadBulk(this._imagesData.view_day[floor], (url, image, index) => {
                this._images.view_day[floor][index] = image;
            });
        } else {
            // view night
            await this._bulkImageLoader.loadBulk(this._imagesData.view_night[floor], (url, image, index) => {
                this._images.view_night[floor][index] = image;
            });
        }

        // flats first images
        this._flatsData
            .filter((flat) => flat.floor === floor)
            .forEach((flat) => {
                const flatImages = this._imagesData.flats[flat.name];
                if (!flatImages) return;

                const url = flatImages[0];
                this._bulkImageLoader.load(url).then((image) => {
                    if (!this._images.flats[flat.name]) this._images.flats[flat.name] = [];
                    this._images.flats[flat.name][0] = image;
                });
            });

        if (preloadOtherFloors) {
            // preload other floors before loading view high
            for (let i = 1; i <= this._nbFloors + 1; i++) {
                if (i === floor) continue;
                await this.preloadFloor(i, dayNight, false);
            }

            // load the other time of day
            if (dayNight !== 'day') {
                // view day high
                await this._bulkImageLoader.loadBulk(this._imagesData.view_day_high[floor], (url, image, index) => {
                    this._images.view_day_high[floor][index] = image;
                });
            } else {
                // view night high
                await this._bulkImageLoader.loadBulk(this._imagesData.view_night_high[floor], (url, image, index) => {
                    this._images.view_night_high[floor][index] = image;
                });
            }
        }
    }

    async loadFlat(name) {
        if (!this._imagesData.flats[name]) return;

        await this._bulkImageLoader.loadBulk(this._imagesData.flats[name].plan, (url, image, index) => {
            this._images.flats[name].plan[index] = image;
        });
        await this._bulkImageLoader.loadBulk(this._imagesData.flats[name].view3d, (url, image, index) => {
            this._images.flats[name].view3d[index] = image;
        });
    }

    async flat(name, type, index) {
        // no flat found, return pixel image
        if (!this._imagesData.flats[name]) return this._pixelImage;
        if (!this._imagesData.flats[name][type]) return this._pixelImage;

        // no image for specific index
        if (!this._imagesData.flats[name][type][index]) {
            // there is an image on index 0, get it
            if (this._imagesData.flats[name][type][0]) index = 0;
            // otherwise return pixel image
            else return this._pixelImage;
        }

        let image = this._images.flats[name][type][index];
        if (image) return image;
        return (this._images.flats[name][type][index] = await this._bulkImageLoader.critical(this._imagesData.flats[name][type][index]));
    }

    flat_url(name, type, index) {
        // preload image
        this.flat(name, type, index);
        // return url
        return this._imagesData.flats[name][type][index];
    }

    async _priority(type, floor, index) {
        let image = this._images[type][floor][index];
        if (image) return image;

        return (this._images[type][floor][index] = await this._bulkImageLoader.priority(this._imagesData[type][floor][index]));
    }

    async _critical(type, floor, index) {
        let image = this._images[type][floor][index];
        if (image) return image;

        return (this._images[type][floor][index] = await this._bulkImageLoader.critical(this._imagesData[type][floor][index]));
    }

    view_low(floor, index, dayNight) {
        const image = this._images[`view_${dayNight}_low`][floor][index];
        if (!image) return this._images.view_day_low[floor][index];
        return image;
    }

    view(floor, index, dayNight) {
        const image = this._images[`view_${dayNight}`][floor][index];
        if (!image) return this._images.view_day[floor][index];
        return image;
    }

    loadView(floor, index, dayNight) {
        if (!this._images[`view_${dayNight}`]) return this._priority('view_day', floor, index);
        return this._priority(`view_${dayNight}`, floor, index);
    }

    async view_high(floor, index, dayNight) {
        if (!this._images[`view_${dayNight}_high`][floor][index]) return await this._critical('view_day_high', floor, index);
        return await this._critical(`view_${dayNight}_high`, floor, index);
    }
}
