import React from 'react';
import styles from './styles.module';
import MiscUtils from 'util/MiscUtils';

export default function ({ visible, logoURL, title, address }) {
    return (
        <div className={styles.address + ' ' + (visible ? styles.address_visible : '')} id="address">
            <div className={styles.address_logo}>
                <div className={styles.address_logo_image} style={{ backgroundImage: `url(${logoURL})` }}></div>
            </div>
            <div className={styles.address_infos}>
                <div className={styles.address_infos_title}>{title}</div>
                <div className={styles.address_infos_desc}>{MiscUtils.splitNewline(address)}</div>
            </div>
        </div>
    );
}
