// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__flatcarousel--RkxQa {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 40px;\n  color: var(--color-black); }\n  .styles-module__flatcarousel--RkxQa > * {\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    width: 100%;\n    height: 100%; }\n", "",{"version":3,"sources":["styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,yBAAyB,EAAE;EAC3B;IACE,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY,EAAE","file":"styles.module.scss","sourcesContent":[".flatcarousel {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 40px;\n  color: var(--color-black); }\n  .flatcarousel > * {\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    width: 100%;\n    height: 100%; }\n"]}]);
// Exports
exports.locals = {
	"flatcarousel": "styles-module__flatcarousel--RkxQa"
};
module.exports = exports;
