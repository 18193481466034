// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__viewerWrapper--1EYwm {\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 4.16667vw; }\n", "",{"version":3,"sources":["styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,MAAM;EACN,QAAQ;EACR,iBAAiB,EAAE","file":"styles.module.scss","sourcesContent":[".viewerWrapper {\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 4.16667vw; }\n"]}]);
// Exports
exports.locals = {
	"viewerWrapper": "styles-module__viewerWrapper--1EYwm"
};
module.exports = exports;
