import React, { useRef } from 'react';
import styles from './styles.module';

export default function({ value, onChange }) {
    const rangeRef = useRef();

    function change() {
        if (!rangeRef.current) return;
        onChange(rangeRef.current.value);
    }

    return <input className={styles.root} ref={rangeRef} type="range" min={0} max={100} step={1} value={value} onInput={change} onChange={change}></input>;
}
