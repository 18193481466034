import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module';

import HScrollbar from './HScrollbar';
import BlockAppt from 'components/BlockAppt';
import MouseUtils from 'util/MouseUtils';
import { useFavorites, removeFavorite, listFavorites } from 'hooks/useFavorites';
import useWindowSize from 'hooks/useWindowSize';

function clamp(value, min, max) {
    return Math.max(min, Math.min(max, value));
}

export default function ({ flats }) {
    const [scrollRatio, setScrollRatio] = useState(0);
    const [showScrollBar, setShowScrollBar] = useState(false);
    const containerRef = useRef();
    const contentRef = useRef();
    const favorites = useFavorites();
    const windowSize = useWindowSize();

    function onResizeAppt(height) {
        if (!contentRef.current) return;
        contentRef.current.style.height = `${height}px`;
        contentRef.current.style.visibility = 'visible';
    }

    useEffect(() => {
        if (!containerRef.current) return;
        if (!contentRef.current) return;

        const container = containerRef.current;
        const content = contentRef.current;

        function onWheel(evt) {
            setScrollRatio((value) => clamp(value + Math.sign(evt.deltaY) * 5, 0, 100));
        }

        let touching = false;
        let dragging = false;
        let startMouseX = 0;
        let startScrollRatio = 0;
        function onMouseDown(evt) {
            touching = true;
        }

        function onMouseMove(evt) {
            if (!touching) return;

            const mouseX = MouseUtils.getPosition(evt).x;

            if (!dragging) {
                setScrollRatio((ratio) => (startScrollRatio = ratio));
                dragging = true;
                startMouseX = mouseX;
                return;
            }

            const diffX = startMouseX - mouseX;

            const diffScrollX = Math.max(0, content.clientWidth - container.clientWidth);

            if (diffScrollX <= 0) setScrollRatio(0);
            else setScrollRatio(clamp(startScrollRatio + (diffX * 100) / diffScrollX, 0, 100));
        }

        function onMouseUp(evt) {
            touching = false;
            dragging = false;
        }

        document.body.addEventListener('wheel', onWheel, false);
        container.addEventListener('mousedown', onMouseDown, false);
        container.addEventListener('touchstart', onMouseDown, false);
        document.body.addEventListener('mousemove', onMouseMove, false);
        document.body.addEventListener('touchmove', onMouseMove, false);
        document.body.addEventListener('mouseup', onMouseUp, false);
        document.body.addEventListener('touchend', onMouseUp, false);
        document.body.addEventListener('mouseleave', onMouseUp, false);
        document.body.addEventListener('touchleave', onMouseUp, false);

        return () => {
            document.body.removeEventListener('wheel', onWheel, false);
            container.removeEventListener('mousedown', onMouseDown, false);
            container.removeEventListener('touchstart', onMouseDown, false);
            document.body.removeEventListener('mousemove', onMouseMove, false);
            document.body.removeEventListener('touchmove', onMouseMove, false);
            document.body.removeEventListener('mouseup', onMouseUp, false);
            document.body.removeEventListener('touchend', onMouseUp, false);
            document.body.removeEventListener('mouseleave', onMouseUp, false);
            document.body.removeEventListener('touchleave', onMouseUp, false);
        };
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        const content = contentRef.current;

        if (!containerRef.current) return;
        if (!contentRef.current) return;

        const containerHeight = containerRef.current.clientHeight;
        contentRef.current.style.transform = '';
        const contentHeight = contentRef.current.clientHeight;
        const scale = Math.min(1, containerHeight / contentHeight);

        const diffScale = (1 - scale) * content.clientWidth;
        const diffX = Math.max(0, content.clientWidth - container.clientWidth - diffScale);
        const scrollX = (scrollRatio * diffX) / 100;

        contentRef.current.style.transform = `translate3d(-${scrollX}px, -50%, 0) scale(${scale})`;

        setShowScrollBar(content.clientWidth > container.clientWidth);
    }, [scrollRatio, windowSize, favorites]);

    return (
        <div className={styles.root}>
            <div className={styles.titleWrapper}>
                <div className={styles.title}>favoris</div>
            </div>
            <div className={styles.content}>
                <div className={styles.favorites} ref={containerRef}>
                    <div className={styles.favorites_wrapper} ref={contentRef}>
                        {listFavorites(flats).map((flat) => (
                            <div key={flat.name}>
                                <BlockAppt flat={flat} autoScale={false} onResize={onResizeAppt} onClose={() => removeFavorite(flat)} forceParentHeight={contentRef.current && contentRef.current.clientHeight} />
                            </div>
                        ))}
                    </div>
                </div>
                {favorites.length <= 0 && <div className={styles.nofavorite}>veuillez ajouter vos favoris</div>}
                <div className={styles.scrollbar}>{showScrollBar && <HScrollbar value={scrollRatio} onChange={setScrollRatio} />}</div>
            </div>
        </div>
    );
}
